import state from './state.js'
import Users from '../services/api.js'

export default {
  namespaced: true,
  state,
  mutations: {
    SET_ALL_USERS(state, users) {
      state.userList = users
    },
    SET_ALL_ROLES(state, users) {
      state.roles = users
    },
    SET_ONE_USER(state, user) {
      state.userDetail = user
    },
  },
  actions: {
    async FETCH_ALL_ROLES({ commit }) {
      return new Promise((resolve, reject) => {
        Users.getRoles()
          .then(r => {
            commit('SET_ALL_ROLES', r.data)
            resolve(r.data)
          })
          .catch(reject)
      })
    },

    async FETCH_ALL_USERS({ commit }, params) {
      const { data } = await Users.getAll(params)
      commit('SET_ALL_USERS', data)
    },

    // async FETCH_ALL_USERS({ commit }, a) {
    //   return new Promise((resolve, reject) => {
    //     Users.getAll(a)
    //       .then(r => {
    //         commit('SET_ALL_USERS', r.data)
    //         resolve(r.data)
    //       })
    //       .catch(reject)
    //   })
    // },
    async FETCH_ONE_USER({ commit }, params) {
      return new Promise((resolve, reject) => {
        Users.getById(params)
          .then(r => {
            commit('SET_ONE_USER', r.data)
            resolve(r.data)
          })
          .catch(reject)
      })
    },
    async CREATE_USER({}, data) {
      return await Users.createUser(data)
    },
    async PATCH_USER({}, data) {
      return await Users.patch(data)
    },
    async DELETE_USER({}, data) {
      return await Users.delete(data)
    },
  },
}
