export default () => ({
  casesList: {
    results: [],
    count: 0,
  },

  debtCompanyList: {
    results: [],
  },

  debtListId: {
    BASIS: {
      PAY_HISTORY: [],
    },
    BORROWER: {
      PHONE: [],
    },
    GUARANTOR: [],
    TAGS: [],
  },

  debtListPaymentHistoryModal: {
    results: [],
  },

  DEBTProperty: {
    results: [],
  },

  statementOfDebt: {
    results: [],
  },
  // comment
  commentDebt: {
    results: [],
  },
  // comment end

  // document modal
  debtDocument: {
    results: [],
  },
  // document modal end

  // history modal
  debtHistory: {
    results: [],
  },
  // history modal end

  //  **************************** Претензионно-судебная работа ********************************* //
  skDebtJudicalWork: {
    results: [],
  },
  skDebtJudicalWorkFilterdDate: {
    results: [],
  },
  skDebtJudicalWorkClaimsModal: {
    results: [],
  },
  SkDebtAdjudgement: {},
  //  **************************** Претензионно-судебная работа end ********************************* //

  //  ****************************  ССП  ********************************* //
  skDebtCcp: {
    results: [],
  },

  skDebtCcpExecutiveDoc: {
    CONTACT: [],
  },
  skDebtCcpButtonFilter: {
    results: [],
  },

  skDebtCcpIp: {
    results: [],
  },

  skDebtTag: {
    results: [],
  },
  //* ***************************  ССП  end ********************************* //

  DO_STAGE: [
    {
      title: 'Добровольная оплата',
      value: 'voluntary_payment',
    },
    {
      title: 'Претензия',
      value: 'claim',
    },
    {
      title: 'Судебная работа',
      value: 'judical_work',
    },
    {
      title: 'Исполнительный документ',
      value: 'executive_document',
    },
    {
      title: 'Исполнительное производство',
      value: 'executive_work',
    },
    {
      title: 'Приостановлено',
      value: 'stopped',
    },
    {
      title: 'Погашен',
      value: 'paid',
    },
  ],

  // ccp
  EXECUTIVE: [
    {
      title: 'У взыскателя',
      value: 'claimer',
    },
    {
      title: 'У агента',
      value: 'agent',
    },
    {
      title: 'У приставов',
      value: 'pristav',
    },
    {
      title: 'Утерян',
      value: 'lost',
    },
    {
      title: 'ИП приостановлено',
      value: 'stopped',
    },
    {
      title: 'Оплачено',
      value: 'payed',
    },
  ],

  // CCP DOC
  TYPE_CCP: [
    {
      title: 'Исполнительный лист',
      value: 'executive_list',
    },
    {
      title: 'Судебный приказ',
      value: 'court_order',
    },
    {
      title: 'Исполнительная надпись',
      value: 'executive_inscription',
    },
  ],

  // adjudment
  SATISFACTION: [
    {
      title: 'Полное',
      value: 'full',
    },
    {
      title: 'Частичное',
      value: 'part',
    },
    {
      title: 'Не удовлетворено',
      value: 'no',
    },
  ],

  APPEAL: [
    {
      title: 'Да',
      value: 'Y',
    },
    {
      title: 'Нет',
      value: 'N',
    },
  ],
  // adjudment

  // JUDICAL WORK
  JUD_TYPE_LIST: [
    {
      title: 'Первый иск',
      value: 'perviy_isk',
    },
    {
      title: 'Второй иск',
      value: 'vtoroy_isk',
    },
    {
      title: 'Первый судебный приказ',
      value: 'perviy_sudebnyy_prikaz',
    },
    {
      title: 'Второй судебный приказ',
      value: 'vtoroy_sudebnyy_prikaz',
    },
    {
      title: 'Длящиеся требования',
      value: 'dlyashiesya_trebovaniya',
    },
    {
      title: 'Иск на ЮУ',
      value: 'isk_na_YU',
    },
    {
      title: 'Индексация 1',
      value: 'index1',
    },
    {
      title: 'Индексация 2',
      value: 'index2',
    },
  ],

  JUD_STATUS_LIST: [
    {
      title: 'Претензия',
      value: 'claim',
    },
    {
      title: 'Подготовка документов',
      value: 'doc_preparation',
    },
    {
      title: 'Судебное производство',
      value: 'judicial_proceeding',
    },
    {
      title: 'Удовлетворено',
      value: 'satisfied',
    },
    {
      title: 'Удовлетворено частично',
      value: 'partially_satisfied',
    },
    {
      title: 'Отказано',
      value: 'refused',
    },
    {
      title: 'Оспаривается',
      value: 'disputing',
    },
    {
      title: 'Исполнительный документ',
      value: 'executive_document',
    },
  ],

  SEND_TYPE: [
    {
      title: 'Электронно',
      value: 'electronic',
    },
    {
      title: 'Почтовое отправление',
      value: 'post',
    },
    {
      title: 'Нарочно',
      value: 'purposely',
    },
  ],

  ELECTRONIC_STATUS: [
    {
      title: 'Отправлено в суд',
      value: 'sent_to_court',
    },
    {
      title: 'Зарегистрировано в суде',
      value: 'registered',
    },
    {
      title: 'Передано на рассмотрение судье',
      value: 'judge',
    },
    {
      title: 'Технический отказ',
      value: 'technical_deny',
    },
  ],
  DATE: [
    {
      title: 'Да',
      value: 'yes',
    },
    {
      title: 'Нет',
      value: 'no',
    },
    {
      title: 'Не важно',
      value: 'nothing',
    },
  ],
  // JUDICAL WORK

  /// tab

  tabActive: 'tab1',
  dosserId: {},
})
