import ApiServices from '@/services/apiServices'

export class Dk extends ApiServices {
  getDk(params) {
    return this.http.get('/dk/dkLegalDebtWork', { params })
  }

  getCurrentId(params) {
    return this.http.get('/dk/get-active-legal-debt', { params })
  }

  getDkStatistics(params) {
    // return this.http.get('/dk/dk-work-header-statistics', { params })
    return this.http.get('/dk/dk-header-legal-statistics', { params })
  }
}
export default new Dk('/dk')
