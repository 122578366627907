const Module = () => import("./Module.vue");
const Home = () => import("./views/Home.vue");

const moduleRoute = {
  path: "/roles",
  component: Module,
  children: [
    {
      path: "",
      component: Home,
      name: "roles-home",
    },
  ],
};

export default (router) => {
  router.addRoutes([moduleRoute]);
};
