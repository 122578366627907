export default {
  methods: {
    $_showAreYouSureModal() {
      return this.$bvModal.msgBoxConfirm('Вы действительно хотите удалить эту запись?', {
        title: 'Удалить',
        okVariant: 'danger',
        okTitle: 'Подтвердить',
        cancelTitle: 'Отменить',
        hideHeaderClose: false,
        centered: true,
      })
    },

    $_showAreYouSureYesNo() {
      return this.$bvModal.msgBoxConfirm('Вы действительно хотите ?', {
        title: 'Отказ от взаимодействия с 3ми лицами',
        okVariant: 'danger',
        okTitle: 'Подтвердить',
        cancelTitle: 'Отменить',
        hideHeaderClose: false,
        centered: true,
      })
    },

    $_showAreYouDie() {
      return this.$bvModal.msgBoxConfirm('Вы действительно хотите изменить?', {
        title: 'Умер',
        okVariant: 'danger',
        okTitle: 'Подтвердить',
        cancelTitle: 'Отменить',
        hideHeaderClose: false,
        centered: true,
      })
    },
  },
}
