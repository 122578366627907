import ApiServices from '@/services/apiServices'

export class LegalPort extends ApiServices {
  getDkLegalContact(params) {
    return this.http.get('/dk/dkLegalContact/', { params })
  }

  getClaimantsPortStatistics(params) {
    return this.http.get('/dk/dk-header-legal-statistics', { params })
  }
}
export default new LegalPort('/')
