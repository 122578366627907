import RegistriesState from './state'
import Logs from '../services/api'

export default {
  namespaced: true,
  state: RegistriesState,
  actions: {
    async FETCH_UPLOADS_LOGS({ state }, payload) {
      return new Promise((resolve, reject) => {
        Logs.getUploadLogs(payload)
          .then(r => {
            state.logs = r.data
            resolve(r.data)
          })
          .catch(reject)
      })
    },
    CREATE_UPLOADS_LOGS(_, data) {
      return Logs.createUploadLogs(data)
    },

    async FETCH_EXCEL(_, params) {
      return new Promise(resolve => {
        Logs.getXLS(params)
          .then(r => {
            resolve(r.data)
          })
      })
    },
  },
}
