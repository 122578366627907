import ApiServices from '@/services/apiServices'

export class Cart extends ApiServices {
  constructor(url) {
    super(url)
  }

  getByName(name) {
    // alert(name);
  }

  getByType(type) {
    this.http.get(this.apiUrl + type)
  }

  // ARCHIVE
  getSkDebtArchive(params) {
    return this.http.get(`${this.apiUrl}/skSetDebt/archive_debt/`, { params })
  }

  getSkDebtExcelArchive(params) {
    return this.http({
      method: 'get',
      url: `${this.apiUrl}/skSetDebt/archive_debt_export/`,
      params,
      timeout: 0,
      responseType: 'blob',
    })
  }
}

export default new Cart('/debts')
