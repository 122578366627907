<template>
  <BRow class="mx-1 justify-content-between mt-1">
    <div class="d-flex align-items-center">
      <span class="text-nowrap">
        Показать по
      </span>
      <BFormSelect
        v-model="filterData.page_size"
        :options="options"
        class="mx-1"
        @change="
          filterData.page = 1;
          inputPage = 1;
          emitInput();"
      />
      <span class="text-nowrap">
        строк( {{ count }} )
      </span>
    </div>

    <BForm
      v-if="goToPage"
      class="size_form"
      @submit.prevent="filterData.page = inputPage || 1"
    >
      <BInputGroup>
        <BInputGroupPrepend>
          <BButton
            variant="outline-secondary"
          >
            Перейти на стр.
          </BButton>
        </BInputGroupPrepend>
        <BFormInput
          v-model="inputPage"
          :formatter="(v) => $_numberFormatter(v, 10)"
        />
        <BInputGroupAppend>
          <BButton
            variant="success"
            type="submit"
          >
            Выбрать
          </BButton>
        </BInputGroupAppend>
      </BInputGroup>
    </BForm>

    <BPagination
      v-model="filterData.page"
      :per-page="filterData.page_size"
      :total-rows="count"
      first-number
      last-number
      align="right"
      prev-class="prev-item"
      next-class="next-item"
      @input="emitInput"
    />
  </BRow>
</template>

<script>
import {
  BRow,
  BForm,
  BFormSelect,
  BInputGroup,
  BFormInput,
  BInputGroupPrepend,
  BInputGroupAppend,
  BButton,
  BPagination,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BForm,
    BFormSelect,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BButton,
    BPagination,
  },
  props: {
    count: {
      type: Number,
      default: 0,
    },
    goToPage: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      options: [3, 5, 10, 100],
      inputPage: null,
      filterData: {
        page_size: 10,
        page: 1,
      },
    }
  },
  mounted() {
    this.emitInput()
  },
  methods: {
    emitInput() {
      this.$emit('input', this.filterData)
    },
  },
}
</script>

<style>
.size_form{
  width: 40%
}
</style>
