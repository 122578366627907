export default () => ({
  dkList: {
    results: [],
  },

  currentId: {
    current_id: null,
    next_id: null,
    prev_id: null,
  },

  dkStatistics: {
    count_do: 0,
    summa_do: 0,
    call_count: 0,
    promise_count: 0,
    finished_promise_count: 0,
    closed_debt: 0,
  },
})
