import ApiServices from '@/services/apiServices'

export class Not extends ApiServices {
  getSkDebtCompanyList(params) {
    return this.http.get('/dk/dkSetDebt/', { params })
  }

  getSkDebtCompanyListFilter(params) {
    return this.http.get('/debts/company/', { params })
  }
}
export default new Not('/')
