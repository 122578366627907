export const SET_DOC_STATUS = 'SET_DOC_STATUS'
export const SET_ALL_USERS = 'SET_ALL_USERS'
export const SET_UTILS_DOC_LAZY = 'SET_UTILS_DOC_LAZY'
export const SET_RECEIVED_FROM_LAZY = 'SET_RECEIVED_FROM_LAZY'
export const SET_DEBT_CLIENTS_LAZY = 'SET_DEBT_CLIENTS_LAZY'
export const SET_DEBT_CLIENTS_LAZY2 = 'SET_DEBT_CLIENTS_LAZY2'
export const SET_DEBT_FILE_TYPES_LAZY_MODAL = 'SET_DEBT_FILE_TYPES_LAZY_MODAL'
export const SET_COMPANY_SHORT_LIST = 'SET_COMPANY_SHORT_LIST'
export const SET_BORROWER_LIST_LAZY = 'SET_BORROWER_LIST_LAZY'
export const SET_SK_DEBT_LIST_LAZY = 'SET_SK_DEBT_LIST_LAZY'
export const SET_DEBT_FILE_TYPES_LAZY = 'SET_DEBT_FILE_TYPES_LAZY'
export const SET_BREADCRUMB = 'SET_BREADCRUMB'
// clear states
export const CLEAR_BORROWER_LIST_LAZY = 'CLEAR_BORROWER_LIST_LAZY'
export const CLEAR_SK_DEBT_LIST = 'CLEAR_SK_DEBT_LIST'
export const CLEAR_DEBT_FILE_TYPES = 'CLEAR_DEBT_FILE_TYPES'
export const CLEAR_CLIENT = 'CLEAR_CLIENT'
export const SET_DEBT_SHORT_LIST = 'SET_DEBT_SHORT_LIST'
export const SET_TITLE_LAZY_MODAL = 'SET_TITLE_LAZY_MODAL'
export const SET_UTILS_DOC_LAZY_SECOND = 'SET_UTILS_DOC_LAZY_SECOND'
export const SET_PARAMS = 'SET_PARAMS'
