import ApiServices from '@/services/apiServices'

export class Action extends ApiServices {
  getBpmAction(params) {
    return this.http.get('/bpm/bpm_action/', { params })
  }

  createBpmAction(data) {
    return this.http.post('/bpm/bpm_action/', data)
  }

  updateBpmAction({ data, id }) {
    return this.http.patch(`/bpm/bpm_action/${id}`, data)
  }

  deleteBpmAction(id) {
    return this.http.delete(`/bpm/bpm_action/${id}`)
  }

  getBpmActionType(params) {
    return this.http.get('/bpm/action-type', { params })
  }

  getSmsTemplateText(params) {
    return this.http.get('/bpm/sms-template-keys', { params })
  }
}

export default new Action('/')
