export default () => ({
  breadcrumb: [],

  debtClients: {
    count: 0,
    results: [],
  },

  debtClients2: {
    count: 0,
    results: [],
  },
  borrowerList: {
    count: 0,
    results: [],
  },
  borrowerList2: {
    count: 0,
    results: [],
  },
  skDebtList: {
    count: 0,
    results: [],
  },
  debtFileTypes: {
    CONTENT_TYPE: null,
    EXECUTIVE_WORK: [],
    ID: null,
    OBJECT_ID: null,
  },

  debtFileTypesModal: {
    CONTENT_TYPE: null,
    EXECUTIVE_WORK: [],
    ID: null,
    OBJECT_ID: null,
  },

  debtFiles: {
    count: 0,
    results: [],
  },

  // lazy
  docStatus: {
    count: 0,
    results: [],
  },

  companyShortList: {
    count: 0,
    results: [],
  },

  allUsers: {
    count: 0,
    results: [],
  },

  utilsDoc: {
    count: 0,
    results: [],
  },

  utilsDocSecond: {
    count: 0,
    results: [],
  },

  receivedFrom: {
    count: 0,
    results: [],
  },

  docAttach: {
    FILES: {
      files: [],
    },
    DOC_TYPE: [],
    BORROWER: {},
    DOC_STATUS: {},
    DEBT_OR_EXECUTIVE_WORK: {},
    RECEIVED_FROM: {},
    ORGANIZATION: {},
    RESPONSIBLE: {},
    SK_DEBT: {},
  },

  filters: {},
  step: 0,

  activeBorrow: '',
  activeFileType: null,
  debtFiles2: {},
  borrowSpin: false,

  debtShortList: {
    count: 0,
    results: [],
  },

  actionType: {
    count: 0,
    results: [],
  },

  params: {
    date: {},
    search_document: "",
    received_from: [],
    responsible: [],
    doc_status: [],
    title: [],
  }
})
