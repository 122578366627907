const Module = () => import('./Module.vue')
const Home = () => import('./views/Home.vue')
const AddEdit = () => import('./views/AddEdit.vue')

const moduleRoute = {
  path: '/users',
  component: Module,
  children: [
    {
      path: '',
      component: Home,
      name: 'users-home',
    },
    {
      path: 'add',
      name: 'users-add',
      component: AddEdit,
    },
    {
      path: 'edit/:id',
      name: 'users-edit',
      component: AddEdit,
    },
  ],
}

export default router => {
  router.addRoutes([moduleRoute])
}
