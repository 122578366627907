export default {
  methods: {
    $_numberFormatter: (v, len = 1) => v.substring(0, len).replace(/[^0-9]/gm, ''),

    $_convertToFormdata(item) {
      const form_data = new FormData()
      for (const key in item) {
        if (Array.isArray(item[key])) {
          item[key].forEach(e => {
            form_data.append(key, e)
          })
        } else {
          form_data.append(key, item[key])
        }
      }
      return form_data
    },

    $_moneyFormatter(num) {
      return new Intl.NumberFormat('ru-Ru', {
        style: 'currency',
        currency: 'RUB',
        // maximumFractionDigits: 0,
      }).format(num)
    },
  },
}
