import http from '@/libs/axios'

export default class ApiServices {
  constructor(url) {
    this.baseURL = process.env.VUE_APP_BACKEND
    this.apiUrl = url
    this.http = http
  }

  async getAll(params) {
    return await this.http.get(this.apiUrl, { params })
  }

  async getById(params) {
    return await this.http({
      method: 'get',
      url: `${this.apiUrl}/${params}`,
    })
  }

  async post(data) {
    return await this.http({
      method: 'post',
      url: this.apiUrl,
      data,
    })
  }

  async put(conf) {
    return await this.http.get(this.apiUrl, { data: conf })
  }

  async patch(data) {
    return await this.http({
      method: 'patch',
      url: `${this.apiUrl}/${data.id}`,
      data,
    })
  }

  async delete(params) {
    return await this.http.delete(`${this.apiUrl}/${params.id}`)
  }
}
