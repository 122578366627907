export default () => ({
  document_types: {
    results: [],
  },
  forms: {
    results: [],
  },
  claimantsData: {
    results: [],
  },

  // doc type
  utilsDocType: {
    results: [],
  },
  // doc type

  // edo
  edoDoc: {
    count: 0,
    results: [],
  },

  receiveForm: {
    count: 0,
    results: [],
  },

  docStatus: {
    count: 0,
    results: [],
  },

  // title
  titleList: {
    results: [],
    count: 0,
  },
})
