import state from './state.js'
import ref from '../serveces/api.js'

export default {
  namespaced: true,
  state,
  actions: {
    async FETCH_DOCUMENT_TYPES(context, params) {
      return new Promise((resolve, reject) => {
        ref.getDocumentTypes(params)
          .then(r => {
            context.state.document_types = r.data
            resolve(r.data)
          })
          .catch(reject)
      })
    },

    ADD_DOCUMENT_TYPE(_, data) {
      return ref.creatAction(data)
    },

    EDIT_DOCUMENT_TYPE(_, data) {
      return ref.editDocumentType(data)
    },

    DELETE_DOCUMENT_TYPE(_, id) {
      return ref.deleteDocumentType(id)
    },
    // Вид работ

    async FETCH_WORK_TYPE(context, params) {
      return new Promise((resolve, reject) => {
        ref.getWork(params)
          .then(r => {
            context.state.forms = r.data
            resolve(r.data)
          })
          .catch(reject)
      })
    },

    ADD_WORK_TYPE(_, data) {
      return ref.createCommentDebt(data)
    },

    EDIT_WORK_TYPE(_, data) {
      return ref.editWork(data)
    },

    DELETE_WORK_TYPE(_, id) {
      return ref.deleteWork(id)
    },

    // claimant
    async FETCH_CLAIMANT_TYPE(context, params) {
      return new Promise((resolve, reject) => {
        ref.getClaimantsList(params)
          .then(r => {
            context.state.claimantsData = r.data
            resolve(r.data)
          })
          .catch(reject)
      })
    },

    CREATE_CLAIMANT_TYPE(_, data) {
      return ref.createClaimant(data)
    },

    UPDATE_CLAIMANT_TYPE(_, data) {
      return ref.updateClaimant(data)
    },

    DELETE_CLAIMANT_TYPE(_, data) {
      return ref.deleteClaimant(data)
    },

    // doc type
    ADD_UTILS_DOC_TYPE(_, data) {
      return ref.createUtilsDocType(data)
    },

    // doc type
    UPDATE_UTILS_DOC_TYPE(_, data) {
      return ref.updateUtilsDocType(data)
    },

    // doc type delete
    DELETE_UTILS_DOC_TYPE(_, data) {
      return ref.deleteUtilsDocType(data)
    },

    async FETCH_UTILS_DOC_TYPE(context, params) {
      return new Promise((resolve, reject) => {
        ref.getUtilsDocType(params)
          .then(r => {
            context.state.utilsDocType = r.data
            resolve(r.data)
          })
          .catch(reject)
      })
    },

    // fileReference EDO
    ADD_EDO_DOC(_, data) {
      return ref.createEdoDoc(data)
    },

    UPDATE_EDO_DOC(_, data) {
      return ref.updateEdoDoc(data)
    },

    DELETE_EDO_DOC(_, id) {
      return ref.deleteEdoDoc(id)
    },

    async FETCH_EDO_DOC(context, params) {
      return new Promise((resolve, reject) => {
        ref.getEdoDoc(params)
          .then(r => {
            context.state.edoDoc = r.data
            resolve(r.data)
          })
          .catch(reject)
      })
    },

    ADD_RECEIVED_FROM(_, data) {
      return ref.createReceiveForm(data)
    },

    UPDATE_RECEIVED_FROM(_, data) {
      return ref.updateReceiveForm(data)
    },

    DELETE_RECEIVED_FROM(_, id) {
      return ref.deleteReceiveForm(id)
    },

    async FETCH_RECEIVED_FROM(context, params) {
      try {
        const response = await ref.getReceiveForm(params)
        context.state.receiveForm = response.data
        return response.data
      } catch (error) {
        throw error
      }
    },

    ADD_DOC_STATUS(_, data) {
      return ref.createDocStatus(data)
    },

    UPDATE_DOC_STATUS(_, data) {
      return ref.updateDocStatus(data)
    },

    DELETE_DOC_STATUS(_, id) {
      return ref.deleteDocStatus(id)
    },

    async FETCH_DOC_STATUS(context, params) {
      try {
        const response = await ref.getDocStatus(params)
        context.state.docStatus = response.data
        return response.data
      } catch (error) {
        throw error
      }
    },

    // title
    async FETCH_TITLE(context, params) {
      try {
        const { data } = await ref.getTitleList(params)
        context.state.titleList = data
        return data
      } catch (e) {
        throw e
      }
    },

    CREATE_TITLE(_, data) {
      return ref.createTitle(data)
    },

    UPDATE_TITLE(_, data) {
      return ref.updateTitle(data)
    },

    DELETE_TITLE(_, id) {
      return ref.deleteTitle(id)
    },
  },
}
