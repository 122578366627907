import dk from '../serveces/api'
import state from './state'
import mutations from './mutations'

export default {
  namespaced: true,
  state,
  mutations,

  actions: {
    async FETCH_DK_LIST(context, params) {
      return new Promise((resolve, reject) => {
        dk.getDk(params)
          .then(r => {
            context.state.dkList = r.data
            resolve(r.data)
          })
          .catch(reject)
      })
    },

    CREATE_GUARANTOR(_, data) {
      return dk.createGuarantor(data)
    },

    async FETCH_DK_DEBT_ID(context, params) {
      return new Promise((resolve, reject) => {
        dk.getDkDebtId(params)
          .then(r => {
            context.state.dkDebt = r.data
            resolve(r.data)
          })
          .catch(reject)
      })
    },

    UPDATE_CLAIMER_COMPANY(_, data) {
      return dk.updateClaimerCompany(data)
    },

    UPDATE_BASIS_CONTACT(_, data) {
      return dk.updateBasisContract(data)
    },

    UPDATE_BASIS_BASIS(_, data) {
      return dk.updateBasis(data)
    },

    // statistic cards

    async FETCH_DK_PAYMENT_HISTORY(context, params) {
      return new Promise((resolve, reject) => {
        dk.getSkDebTPaymentHistoryModal(params)
          .then(r => {
            context.state.paymentHistory = r.data
            resolve(r.data)
          })
          .catch(reject)
      })
    },

    async FETCH_DK_DEBT_OTHER(context, params) {
      return new Promise((resolve, reject) => {
        dk.getDkDebtOther(params)
          .then(r => {
            context.state.dkDebtOther = r.data
            resolve(r.data)
          })
          .catch(reject)
      })
    },

    async FETCH_DK_DEBT_OTHER_SECOND(context, params) {
      return new Promise((resolve, reject) => {
        dk.getDkDebtOther(params)
          .then(r => {
            context.state.dkDebtOtherSecond = r.data
            resolve(r.data)
          })
          .catch(reject)
      })
    },

    CREATE_UTILS_COMMENT(_, data) {
      return dk.createCommentDebt(data)
    },

    async FETCH_UTILS_COMMENT2({ commit }, params) {
      return new Promise((resolve, reject) => {
        dk.getUtilsComment(params)
          .then(r => {
            commit('SET_UTILS_COMMIT', r.data)
            // context.state.utilsComments = r.data
            resolve(r.data)
          })
          .catch(reject)
      })
    },

    DELETE_COMMENT(_, id) {
      return dk.deleteUtilsComment(id)
    },

    // document modal debt

    CREATE_ATTACH_DOC(_, data) {
      return dk.createDebtDocument(data)
    },

    async FETCH_ATTACH_DOC(context, params) {
      return new Promise((resolve, reject) => {
        dk.getDebtDocument(params)
          .then(r => {
            context.state.attachDoc = r.data
            resolve(r.data)
          })
          .catch(reject)
      })
    },

    // promise
    CREATE_PROMISE(_, data) {
      return dk.createPromise(data)
    },

    CREATE_HISTORY(_, data) {
      return dk.createHistory(data)
    },

    async FETCH_PROMISE_LIST(context, params) {
      return new Promise((resolve, reject) => {
        dk.getPromiseList(params)
          .then(r => {
            context.state.promiseList = r.data
            resolve(r.data)
          })
          .catch(reject)
      })
    },

    async FETCH_HISTORY_TIMELINE(context, params) {
      try {
        const response = await dk.getHistoryTimeLine(params)
        const responseData = response.data

        context.state.historyTimeLine.next = responseData.next
        context.state.historyTimeLine.count = responseData.count

        if (params.page === 1) {
          context.state.historyTimeLine = responseData
        } else {
          context.state.historyTimeLine.results.push(...responseData.results)
        }

        return responseData
      } catch (error) {
        throw error
      }
    },

    // new actions
    async FETCH_LEGAL_CONTACT(context, params) {
      const { data } = await dk.getLegalContact(params)
      context.state.legalContact = data
    },

    async FETCH_LEGAL_JUDICAL_WORK(context, params) {
      const { data } = await dk.getLegalJudicalWork(params)
      context.state.LegalJudicalWork = data
    },

    async FETCH_LEGAL_JUDICAL_EXECUTION(context, params) {
      const { data } = await dk.getLegalJudicalExecution(params)
      context.state.LegalJudicalExecution = data
    },

    async FETCH_DK_CONTACT_BORROWER(context, params) {
      const { data } = await dk.getDkContactBorrower(params)
      context.state.contactBorrower = data
    },

    async FETCH_DK_HEADER_STA(context, params) {
      const { data } = await dk.getDkHeaderSta(params)
      context.state.dkHeaderSta = data
    },

    async FETCH_DK_HEADER_CALL_HISTORY(context, params) {
      const { data } = await dk.getDkHeaderCallHistory(params)
      context.state.dkHeaderCallHistory = data
    },

    async FETCH_DK_HEADER_SMS_HISTORY(context, params) {
      const { data } = await dk.getDkHeaderSmsHistory(params)
      context.state.dkHeaderSmsHistory = data
    },

    async FETCH_DK_CALL_STATUS(context, params) {
      const { data } = await dk.getDkCallStatus(params)
      context.state.dkCallStatus = data
    },

    CREATE_CALL_HISTORY(_, data) {
      return dk.createCallHistory(data)
    },
  },
}
