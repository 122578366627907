import state from './state'
import LegalPort from '../serveces/api'

export default {
  namespaced: true,
  state,
  actions: {
    async FETCH_DK_LEGAL_CONTACT(context, params) {
      return new Promise((resolve, reject) => {
        LegalPort.getDkLegalContact(params)
          .then(r => {
            context.state.dkLegalContact = r.data
            resolve(r.data)
          })
          .catch(reject)
      })
    },

    async FETCH_CLAIMANT_PORT_STATISTICS(context, params) {
      const res = await LegalPort.getClaimantsPortStatistics(params)
      context.state.statistics = res.data
    },
  },
}
