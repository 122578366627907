const Module = () => import('./Module.vue')
const Home = () => import('./views/Home.vue')

const moduleRoute = {
  path: '/import-registries',
  component: Module,
  children: [
    {
      path: '',
      component: Home,
      name: 'import-registries',
    },
  ],
}

export default router => {
  router.addRoutes([moduleRoute])
}
