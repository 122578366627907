const Module = () => import('./Module.vue')
const collectorHome = () => import('./components/detailIndex.vue')
const moduleRoute = {
  path: '/collector',
  component: Module,
  children: [
    {
      path: '/collector/:id/',
      name: 'collector-id',
      component: collectorHome,
    },
  ],
}

export default router => {
  router.addRoutes([moduleRoute])
}
