export default () => ({
  claimantsData: {
    count: 0,
    results: [],
  },

  statistics: {},

  debtCompanyList: {
    count: 0,
    results: [],
  },
  secretKey: {
    secret_key: '',
  },
})
