const Module = () => import('./Module.vue')
const SKEntity = () => import('./views/SKEntity.vue')
const SKEntityDetails = () => import('./pages/SKEntityDetails.vue')

const moduleRoute = {
  path: '/sk-entity',
  component: Module,
  children: [
    {
      path: '/',
      name: 'sk-entity',
      component: SKEntity,
    },
    {
      path: ':id',
      name: 'sk-entity-details',
      component: SKEntityDetails,
    },
  ],
  permissions: ['employee', 'rester_employee', 'dk_operator', 'dk_manager'],
}

export default router => {
  router.addRoutes([moduleRoute])
}
