const Module = () => import('./Module.vue')
const Home = () => import('./views/Home.vue')

const moduleRoute = {
  path: '/actions',
  component: Module,
  children: [
    {
      path: '/',
      name: 'actions-home',
      component: Home,
    },
  ],
  permissions: ['employee', 'rester_employee'],
}

export default router => {
  router.addRoutes([moduleRoute])
}
