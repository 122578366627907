// import * as types from './mutation-types.js'

export default {
  SET_ACTIVE(state, data) {
    state.active = data
  },

  SET_EMPTY(state) {
    state.historyTimeLine = {
      results: [],
    }
  },

  SET_CALL(state, data) {
    state.call = data
  },

  SET_COMMENT_LIST(state, data) {
    state.utilsComments = {
      count: data.count,
      next: data.next,
      results: [
        ...state.utilsComments.results,
        ...data.results,
      ],
    }
  },

  SET_UTILS_COMMIT_EMPTY(state) {
    state.utilsComments = {
      count: 0,
      results: [],
    }
  },

  SET_COMMIT_EMPTY(state) {
    state.utilsComments = {
      count: 0,
      results: [],
    }
  },

  SET_EMPTY_REMINDER(state) {
    state.remiderList = {
      count: 0,
      results: [],
    }
  },

  SET_ACTIVETAB(state, data) {
    state.activeTab = data
  },
}
