import ApiServices from '@/services/apiServices'

export class Users extends ApiServices {
  constructor(url) {
    super(url)
  }

  getUploadLogs(params) {
    return this.http.get(`${this.baseURL}/sk_parser/upload/logs`, { params })
  }

  createUploadLogs(data) {
    return this.http.post(`${this.baseURL}/sk_parser/upload/xls`, data)
  }

  getXLS(params) {
    return this.http({
      method: 'get',
      url: `${this.baseURL}/sk_parser/export/logs`,
      params,
      timeout: 0,
      responseType: 'blob',
    })
  }
}

export default new Users('/roles')
