const Module = () => import('./Module.vue')
const Home = () => import('./views/Home.vue')

const moduleRoute = {
  path: '/',
  component: Module,
  children: [
    {
      path: '/reference',
      name: 'reference',
      component: Home,
    },
  ],
}

export default router => {
  router.addRoutes([moduleRoute])
}
