export default () => ({
  callHistory: {
    results: [],
  },
  status2: [
    {
      title: 'Поднял трубку',
      value: 'Поднял трубку',
    },
    {
      title: 'Автоответчик',
      value: 'Автоответчик',
    },
    {
      title: 'Текст не заполнен',
      value: 'Текст не заполнен',
    },
    {
      title: 'Начал слушать',
      value: 'Начал слушать',
    },
    {
      title: 'Закончил слушать',
      value: 'Закончил слушать',
    },
    {
      title: 'Неправильный номер',
      value: 'Неправильный номер',
    },
    {
      title: 'Связь прервалась',
      value: 'Связь прервалась',
    },
    {
      title: 'Обещание',
      value: 'Обещание',
    },
    {
      title: 'Успех',
      value: 'Успех',
    },
    {
      title: 'Отказ',
      value: 'Отказ',
    },
    {
      title: 'Неперспективный',
      value: 'Неперспективный',
    },
    {
      title: 'Не ответил',
      value: 'Не ответил',
    },
    {
      title: 'Без статуса',
      value: 'null',
    },
  ],

  status_call2: [
    {
      title: 'Неопределен',
      value: 'Неопределен',
    },
    {
      title: 'Занято',
      value: 'Занято',
    },
    {
      title: 'Не отвечает',
      value: 'Не отвечает',
    },
    {
      title: 'Недостаточное время обработки',
      value: 'Недостаточное время обработки',
    },
    {
      title: 'Успех',
      value: 'Успех',
    },
    {
      title: 'Пропуск оператором',
      value: 'Пропуск оператором',
    },
    {
      title: 'Оператор отсутствует',
      value: 'Оператор отсутствует',
    },
    {
      title: 'Перезвонить позже',
      value: 'Перезвонить позже',
    },
    {
      title: 'Исключение номера при синхронизации',
      value: 'Исключение номера при синхронизации',
    },
    {
      title: 'Абонент прервал ожидание в очереди',
      value: 'Абонент прервал ожидание в очереди',
    },
    {
      title: 'Отбой из дозванивающихся',
      value: 'Отбой из дозванивающихся',
    },
    {
      title: 'Исключение номера из обработки',
      value: 'Исключение номера из обработки',
    },
    {
      title: 'Исключение абонента из обработки',
      value: 'Исключение абонента из обработки',
    },
    {
      title: 'Неуспех',
      value: 'Неуспех',
    },
    {
      title: 'Системный отбой из очереди',
      value: 'Системный отбой из очереди',
    },
    {
      title: 'Удален оператором',
      value: 'Удален оператором',
    },
    {
      title: 'Входящий звонок. Превышено число подключений',
      value: 'Входящий звонок. Превышено число подключений',
    },
    {
      title: 'Входящий звонок вне периода активности',
      value: 'Входящий звонок вне периода активности',
    },
    {
      title: 'Входящий звонок вне расписания',
      value: 'Входящий звонок вне расписания',
    },
    {
      title: 'Входящий звонок в неактивную задачу',
      value: 'Входящий звонок в неактивную задачу',
    },
    {
      title: 'Превышено время ожидания в очереди',
      value: 'Превышено время ожидания в очереди',
    },
    {
      title: 'Входящий звонок. Операторы отсутствует',
      value: 'Входящий звонок. Операторы отсутствует',
    },
    {
      title: 'Входящий звонок. Операторы заняты',
      value: 'Входящий звонок. Операторы заняты',
    },
    {
      title: 'Входящий звонок. Сбой или задача не найдена',
      value: 'Входящий звонок. Сбой или задача не найдена',
    },
    {
      title: 'Перезвонить на другой номер',
      value: 'Перезвонить на другой номер',
    },
    {
      title: 'Абонент не найден в таблице',
      value: 'Абонент не найден в таблице',
    },
    {
      title: 'Обнаружен факс',
      value: 'Обнаружен факс',
    },
    {
      title: 'Без статуса',
      value: 'null',
    },
  ],

  is_robot: [
    {
      title: 'Автоинформатор',
      value: true,
    },
    {
      title: 'Оператор',
      value: false,
    },
  ],
})
