import * as types from './mutation-types.js'
import { SET_ACTIVE_TAB, SET_COMMENT_DEBT, SET_DEBT_ACTION } from './mutation-types.js'

export default {
  [types.ADD_ITEM](state, item) {
    state.items.push(item)
  },

  [types.REMOVE_ITEM](state, id) {
    state.items = state.items.filter(item => item.id !== id)
  },

  [types.SET_SK_DEBT](state, data) {
    state.casesList = data
  },

  [types.SET_SK_DEBT_COMPANY_LIST](state, data) {
    state.debtCompanyList = data
  },

  [types.SET_SK_DEBT_ID](state, data) {
    state.debtListId = data
  },

  [types.SET_SK_DEBT_PAYMENT_HISTORY_MODAL](state, data) {
    state.debtListPaymentHistoryModal = data
  },

  [types.SET_SK_STATEMENT_OF_DEBT](state, data) {
    state.statementOfDebt = data
  },

  [types.SET_DEBT_PROPERTY_MODAL](state, data) {
    state.DEBTProperty = data
  },
  // comment
  [types.SET_COMMENT_DEBT](state, data) {
    state.commentDebt = data
  },
  // comment end
  // modal document
  [types.SET_DEBT_DOCUMENT](state, data) {
    state.debtDocument = data
  },
  // modal document end

  // history
  [types.SET_SK_DEBT_HISTORY](state, data) {
    state.debtHistory = data
  },
  // history end

  //  **************************** Претензионно-судебная работа ********************************* //
  [types.SET_SK_DEBT_JUDICAL_WORK](state, data) {
    state.skDebtJudicalWork = data
  },
  [types.SET_SK_DEBT_JUDICAL_WORK_FILTERED_DATE](state, data) {
    state.skDebtJudicalWorkFilterdDate = data
  },
  [types.SET_SK_DEBT_JUDICAL_WORK_CLAIMS_MODAL](state, data) {
    state.skDebtJudicalWorkClaimsModal = data
  },
  [types.SET_SK_DEBT_ADJUDGEMENT](state, data) {
    state.SkDebtAdjudgement = data
  },
  //  **************************** Претензионно-судебная работа end ********************************* //

  //  ****************************  ССП  ********************************* //
  [types.SET_SK_DEBT_CCP](state, data) {
    state.skDebtCcp = data
  },

  [types.SET_SK_DEBT_EXECUTIVE_DOCUMENT](state, data) {
    state.skDebtCcpExecutiveDoc = data
  },

  [types.SET_SK_DEBT_CCP_BUTTON_FILTER](state, data) {
    state.skDebtCcpButtonFilter = data
  },

  [types.SET_SK_DEBT_CCP_IP](state, data) {
    state.skDebtCcpIp = data
  },
  //  ****************************  ССП  end********************************* //
  [types.SET_SK_DEBT_TAG](state, data) {
    state.skDebtTag = data
  },

  [types.SET_ACTIVE_TAB](state, data) {
    state.tabActive = data
  },

  [types.SET_DOSSER_ID](state, data) {
    state.dosserId = data
  },
}
