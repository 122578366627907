export default () => ({
  bpmAction: {
    results: [],
  },

  getSmsTemplateText: {
    results: [],
  },

  getBpmActionTypes: {
    results: [],
  },

  getActionTypeTemplate: {
    results: [],
  },
})
