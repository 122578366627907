export default () => ({
  actionsList: {
    results: [],

    count: 4,
  },

  skDebtAction: {
    results: [],
  },
  STATUS: [
    {
      title: 'Новый',
      value: 1,
    },
    {
      title: 'Завершить',
      value: 2,
    },
    {
      title: 'Удалить',
      value: 3,
    },
    {
      title: 'Восстановить',
      value: 4,
    },
    {
      title: 'Оценить',
      value: 5,
    },
  ],
})
