import call from '../serveces/api'
import state from './state'
import mutations from './mutations'

export default {
  namespaced: true,
  state,
  mutations,

  actions: {
    async FETCH_CALL_HISTORY(context, params) {
      return new Promise((resolve, reject) => {
        call.getCallHistory(params)
          .then(r => {
            context.state.callHistory = r.data
            resolve(r.data)
          })
          .catch(reject)
      })
    },

    async FETCH_CALL_EXCEL(_, params) {
      return new Promise(resolve => {
        call.getXLS(params)
          .then(r => {
            resolve(r.data)
          })
      })
    },
  },
}
