export default {
  SET_SK_LEGAL(state, data) {
    state.skLegal = data
  },

  SET_SK_LEGAL_DETAIL(state, data) {
    state.skLegalDetail = data
  },

  SET_SK_LEGAL_DEBT(state, data) {
    state.SetSkLegalDebt = data
  },

  SET_SK_JUDICAL_WORK(state, data) {
    state.skJudicalWork = data
  },

  SET_SK_JUDICAL_WORK_ID(state, data) {
    state.skJudicalWorkId = data
  },

  SET_SK_LEGAL_ADJUDGEMENT(state, data) {
    state.skLegalAdjudgement = data
  },

  SET_SKLEGAL_EXECUTIVE_DOCUMENT(state, data) {
    state.sklegalExecutiveDocument = data
  },

  SET_SKLEGAL_EXECUTIVE_DOCUMENT_ID(state, data) {
    state.sklegalExecutiveDocumentId = data
  },

  SET_SKLEGAL_EXECUTIVE_DOCUMENT_ID_EMPTY(state) {
    state.sklegalExecutiveDocumentId = {
      EXECUTIVE_WORK: {},
    }
  },

  SET_SKLEGAL_EXECUTIVE_WORK(state, data) {
    state.sklegalExecutiveWork = data
  },

  SET_SKLEGAL_EXECUTIVE_WORK_MEPTY(state) {
    state.sklegalExecutiveWork = {
      count: 0,
      results: [],
    }
  },

  SET_SK_TAGS(state, data) {
    state.skTags = data
  },

  SET_ACTIVE_ID(state, data) {
    state.activeId = data
  },
}
