import ApiServices from '@/services/apiServices'

export class Act extends ApiServices {
  constructor(url) {
    super(url)
  }

  getByName(name) {
    // alert(name);
  }

  getByType(type) {
    this.http.get(this.apiUrl + type)
  }

  //  ****************************  Действия ********************************* //
  createDebtAction(data) {
    return this.http.post(`${this.apiUrl}/tasks/`, data)
  }

  updateDebtAction(data) {
    // return this.http.put(`${this.apiUrl}/tasks/${data.id}/`, data)
    return this.http.patch(`${this.apiUrl}/tasks/${data.id}/`, data.data)
  }

  getSkDetActions(params) {
    return this.http.get(`${this.apiUrl}/tasks/`, { params })
  }

  updateActions(data) {
    return this.http.patch(`${this.apiUrl}/tasks/${data.id}/`, data)
  }

  updateActionsCheckbox(data) {
    return this.http.put(`${this.apiUrl}/tasks/update_participants_tasks/`, data)
  }

  getSkDebtExcelAction(params) {
    return this.http({
      method: 'get',
      url: `${this.apiUrl}/export/tasks/`,
      params,
      timeout: 0,
      responseType: 'blob',
    })
  }
  //  ****************************  Действия end ********************************* //
}

export default new Act('/task_manager')
