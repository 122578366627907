export default () => ({
  dkList: {
    results: [],
  },

  // <----------------card tabs---------------->
  active: 1,
  cards: [
    {
      title: 'Другие дела должника',
      icon: 'FileTextIcon',
      active: 1,
      value: 'other_matters',
    },
    {
      title: 'Прикрепленные документы',
      icon: 'PaperclipIcon',
      active: 2,
    },
    {
      title: 'Обещания',
      icon: 'ShieldIcon',
      active: 3,
    },
    {
      title: 'История платежей',
      icon: 'MessageCircleIcon',
      active: 5,
      value: 'payment_history',
    },
    // {
    //   title: 'Комментарии',
    //   icon: 'MessageSquareIcon',
    //   active: 10,
    // },
  ],
  // <----------------card tabs---------------->

  history: [
    {
      title: 'Новый',
      date: '07:25:37/18.07.2022',
      sub: 'Воробьев Максим Денисович',
      color: 'secondary',
    },
    {
      title: 'В процессе',
      date: '07:25:37/18.07.2022',
      sub: 'Воробьев Максим Денисович',
      color: 'warning',
    },
    {
      title: 'Не выполнено',
      date: '07:25:37/18.07.2022',
      sub: 'Воробьев Максим Денисович',
      color: 'danger',
    },
    {
      title: 'Выполнено',
      date: '07:25:37/18.07.2022',
      sub: 'Воробьев Максим Денисович',
      color: 'success',
    },
  ],

  /// /////////////////////////
  dkDebt: {
    CLAIMER_CRM_COMPANY_ID: {},
    BASIS: {
      PAY_HISTORY: [],
    },
    BORROWER: {
      PHONE: [],
    },
    GUARANTOR: [],
    TAGS: [],
  },

  paymentHistory: {
    count: 0,
    results: [],
  },

  dkDebtOther: {
    count: 0,
    results: [],
  },

  dkDebtOther2: {
    count: 0,
    results: [],
  },

  utilsComments: {
    count: 0,
    results: [],
  },

  attachDoc: {
    results: [],
  },

  promiseList: {
    results: [],
  },

  historyTimeLine: {
    results: [],
  },
  call: false,
  skHeaderSta: {
    auto_call: 0,
    manual_call: 0,
    auto_sms: 0,
    manual_sms: 0,
  },
  skCallStatus: {},

  dkHeaderCallHistory: {
    count: 0,
    results: [],
  },

  dkHeaderSmsHistory: {
    count: 0,
    results: [],
  },

  remiderList: {
    count: 0,
    results: [],
  },
  activeTab: null,
})
