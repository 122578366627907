import * as types from './mutation-types.js'

const a = name => ({
  [`ADD_ITEM_${name}`](state, item) {
    state[name].push(item)
  },
  [`REMOVE_ITEM_${name}`](state, id) {
    state[name] = state.items.filter(item => item.id !== id)
  },
})

export default {
  [types.ADD_ITEM](state, item) {
    state.items.push(item)
  },

  [types.REMOVE_ITEM](state, id) {
    state.items = state.items.filter(item => item.id !== id)
  },
  [types.SET_DEBT_ACTION](state, data) {
    state.skDebtAction = data
  },
  SET_UNACTIVE(state, data) {
    state.isActive = data
  },
}
