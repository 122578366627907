import Vue from 'vue'
import { ToastPlugin, ModalPlugin, VBToggle } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import notify from '@/mixins/notify'
import areYouSure from '@/mixins/areYouSure'
import saveFilter from '@/mixins/saveFilter'
import reference from '@/modules/reference'
import VueParticles from 'vue-particles'
// import LetItSnow from 'vue-let-it-snow'
import callHistory from '@/modules/callHistory'
import router from './router'
import store from './store'
import App from './App.vue'
import { registerModules } from './register-modules'
import formatters from './mixins/formatters'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

// modules
import cart from './modules/cart'
import products from './modules/products'
import actions from './modules/actions'
import cases from './modules/cases'
import archive from './modules/archive'
import workplace from './modules/workplace'
import caseCard from './modules/caseCard'
import importRegistries from './modules/importRegistries'
import portfolios from './modules/portfolios'
import collectorDetail from './modules/collectorDetail'

import users from './modules/users'
import roles from './modules/roles'
import statistics from './modules/statistics'

import actionSettings from './modules/actionSettings'
import processSettings from './modules/processSettings'
import fileManagement from './modules/FileManagement'
import dk from './modules/dk'
import notProspective from './modules/notProspective'
import entity from './modules/entity'
import legalPortfolios from './modules/legalPortfolios'
import legalPortfoliosCollector from './modules/legalPortfoliosCollector'
import dkLegalFace from './modules/dkLegalFace'
import todoList from './modules/todoList'

// Vue.use(LetItSnow)

Vue.use(VueParticles)

registerModules({
  products,
  cart,
  actions,
  cases,
  archive,
  workplace,
  caseCard,
  users,
  roles,
  statistics,
  reference,
  importRegistries,
  portfolios,
  collectorDetail,
  callHistory,
  actionSettings,
  processSettings,
  dk,
  fileManagement,
  notProspective,
  entity,
  legalPortfolios,
  legalPortfoliosCollector,
  dkLegalFace,
  todoList,
})

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

Vue.directive('b-toggle', VBToggle)

// Composition API
Vue.use(VueCompositionAPI)

// миксин formatter
Vue.mixin(formatters)
Vue.mixin(saveFilter)
// подключаю миксин уведомлений
Vue.mixin(notify)
Vue.mixin(areYouSure)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
