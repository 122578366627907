export default () => ({
  archiveList: {
    results: [],
  },
  managers: [
    {
      first_name: 'Ismet',
      last_name: 'kadirov',
    },
  ],
  priorities: [
    { name: 'Все', value: '' },
    { name: 'Высокий', value: 'high' },
    { name: 'Средний', value: 'medium' },
    { name: 'Низкий', value: 'low' },
  ],
  claimants: [
    {
      name: 'Все',
    },
  ],
  delayeds: [
    {
      name: 'Все',
    },
  ],
  vips: [
    {
      name: 'Все',
    },
  ],
  regions: [
    {
      name: 'Все',
    },
  ],
  bankrupts: [
    {
      name: 'Все',
    },
  ],
  deads: [
    {
      name: 'Все',
    },
  ],
  cases: [
    {
      name: 'Все',
    },
  ],
  rights: [
    {
      name: 'Все',
    },
  ],
  groups: [
    {
      name: 'Все',
    },
  ],
  payments: [
    {
      name: 'Все',
    },
  ],
})
