import ApiServices from "@/services/apiServices";

export class Cart extends ApiServices {
  constructor(url) {
    super(url);
  }
  getByName(name) {
    // alert(name);
  }
}

export default new Cart("/cart");
