import ApiServices from '@/services/apiServices'

export class Ref extends ApiServices {
  constructor(url) {
    super(url)
  }

  getDocumentTypes(params) {
    return this.http.get(`${this.apiUrl}/action_type/`, { params })
  }

  deleteDocumentType(id) {
    return this.http.delete(`${this.apiUrl}/action_type/${id}/`)
  }

  creatAction(data) {
    return this.http.post(`${this.apiUrl}/action_type/`, data)
  }

  editDocumentType(data) {
    return this.http.patch(`${this.apiUrl}/action_type/${data.id}/`, data)
  }

  // work type
  getWork(params) {
    return this.http.get(`${this.apiUrl}/work_type/`, { params })
  }

  deleteWork(id) {
    return this.http.delete(`${this.apiUrl}/work_type/${id}/`)
  }

  createCommentDebt(data) {
    return this.http.post(`${this.apiUrl}/work_type/`, data)
  }

  editWork(data) {
    return this.http.patch(`${this.apiUrl}/work_type/${data.id}/`, data)
  }

  // doc type
  createUtilsDocType(data) {
    return this.http.post('/utils/doc-type/', data)
  }

  // doc type update
  updateUtilsDocType({
    id,
    ...data
  }) {
    return this.http.patch(`/utils/doc-type/${id}/`, data)
  }

  // doc type delete
  deleteUtilsDocType(id) {
    return this.http.delete(`/utils/doc-type/${id}/`)
  }

  getUtilsDocType(params) {
    return this.http.get('/utils/doc-type/', { params })
  }

  // doc type

  // claimants
  getClaimantsList(params) {
    return this.http.get('/debts/company/', { params })
  }

  createClaimant(data) {
    return this.http.post('/debts/company/', data)
  }

  updateClaimant(data) {
    return this.http.patch(`/debts/company/${data.COMPANY_ID}/`, data)
  }

  deleteClaimant(id) {
    return this.http.delete(`/debts/company/${id}/`)
  }

  // edo
  createEdoDoc(data) {
    return this.http.post('/edo/doc_form/', data)
  }

  updateEdoDoc(data) {
    return this.http.patch(`/edo/doc_form/${data.id}/`, data)
  }

  getEdoDoc(params) {
    return this.http.get('/edo/doc_form/', { params })
  }

  deleteEdoDoc(id) {
    return this.http.delete(`/edo/doc_form/${id}/`)
  }

  createReceiveForm(data) {
    return this.http.post('/edo/received_from/', data)
  }

  updateReceiveForm(data) {
    return this.http.patch(`/edo/received_from/${data.id}/`, data)
  }

  deleteReceiveForm(id) {
    return this.http.delete(`/edo/received_from/${id}/`)
  }

  getReceiveForm(params) {
    return this.http.get('/edo/received_from/', { params })
  }

  createDocStatus(data) {
    return this.http.post('/edo/doc_status/', data)
  }

  updateDocStatus(data) {
    return this.http.patch(`/edo/doc_status/${data.id}/`, data)
  }

  deleteDocStatus(id) {
    return this.http.delete(`/edo/doc_status/${id}/`)
  }

  getDocStatus(params) {
    return this.http.get('/edo/doc_status/', { params })
  }

  // title
  getTitleList(params) {
    return this.http.get('/edo/doc_name/', { params })
  }

  createTitle(data) {
    return this.http.post('/edo/doc_name/', data)
  }

  updateTitle(data) {
    return this.http.patch(`/edo/doc_name/${data.id}/`, data)
  }

  deleteTitle(id) {
    return this.http.delete(`/edo/doc_name/${id}/`)
  }
}

export default new Ref('/task_manager')
