<template>
  <div :style="`width: ${width}px; height: ${height}px`">
    <div class="w-100">
      <p class="mb-0 text-danger text-center">
        {{ errorMessage }}
      </p>
    </div>
    <BButton
      v-if="!streamActive"
      id="startStream"
      size="sm"
      @click="startStream"
    >Переподключиться
    </BButton>
    <video
      ref="stream_video"
      class="w-100 h-100"
      style="transform: scale(-1, 1)"
      :class="streamActive ? 'd-block' : 'd-none'"
      autoplay
    />
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'

export default {
  name: 'StreamComponent',
  components: {
    BButton,
  },
  props: {
    width: {
      type: Number,
      default: 120,
    },
    height: {
      type: Number,
      default: 100,
    },
  },
  data() {
    return {
      initInterval: null,
      streamActive: true,
      errorMessage: '',
    }
  },
  mounted() {
    this.startStream()
  },
  methods: {
    startStream() {
      navigator.getUserMedia = navigator.getUserMedia
        || navigator.webkitGetUserMedia
        || navigator.mozGetUserMedia
        || navigator.msGetUserMedia

      navigator.mediaDevices
        .getUserMedia({
          audio: false,
          video: true,
        })
        .then(stream => {
          this.errorMessage = ''
          this.streamActive = true
          this.$refs.stream_video.srcObject = stream
          stream.oninactive = () => {
            this.streamActive = false
            this.startStream()
          }
          stream.onactive = err => {
            console.log('Stream started')
          }
          stream.onremovetrack = () => {
            this.streamActive = false
            this.startStream()
          }
        })
        .catch(err => {
          this.streamActive = false
          this.errorMessage = err.message
          console.log(err)
        })
    },
  },
}
</script>

<style>
</style>
