<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
  >
    <div
      class="progress"
      style="height: auto"
      aria-valuenow="0"
      aria-valuemin="0"
      aria-valuemax="100"
    >
      <div
        class="progress-bar"
        style="position: fixed; top: 0; height: 4px; z-index: 1032;"
      />
    </div>
    <component
      :is="layout"
    >
      <router-view />
    </component>
  </div>
</template>

<script>
import StreamComponent from '@/components/stream/StreamComponent.vue'

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'

import { useWindowSize, useCssVar } from '@vueuse/core'

import store from '@/store'

import ru from 'vee-validate/dist/locale/ru.json'
import { localize } from 'vee-validate'
import saveFilter from '@/mixins/saveFilter'
import areYouSure from '@/mixins/areYouSure'
import { mapGetters } from 'vuex'

localize('ru', ru)

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
  components: {
    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,
    // Stream
    StreamComponent,
  },
  mixins: [saveFilter, areYouSure],

  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
    ...mapGetters('progressBar', ['getProcessProgress']),
  },
  watch: {
    getProcessProgress(val) {
      const progressBar = document.querySelector('.progress-bar')
      progressBar.style.width = `${val}%`
      if (val === 0) {
        // это что бы било видно анимация
        setTimeout(() => {
          progressBar.style.display = 'block'
        }, 700)
      }
      if (val === 100) {
        // это что бы било видно анимация
        setTimeout(() => {
          progressBar.style.display = 'none'
          store.dispatch('progressBar/UPDATE_PROCESS', 0)
        }, 700)
      }
    },
  },
  mounted() {
    console.log('%cILMA', 'color: #fff; background: #3646AF; font-size: 160px')
    console.log('MADE BY: http://zk.uz')
  },
  beforeCreate() {
    // Set colors in theme
    const colors = [
      'primary',
      'secondary',
      'success',
      'info',
      'warning',
      'danger',
      'light',
      'dark',
    ]

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(
        `--${colors[i]}`,
        document.documentElement,
      ).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(
        useCssVar(
          `--breakpoint-${breakpoints[i]}`,
          document.documentElement,
        ).value.slice(0, -2),
      )
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  setup() {
    const { skin, skinClasses } = useAppConfig()

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
    }
  },
}
</script>

<style lang="scss">
@import "src/assets/scss/media";
.hover-text-underline {
  color: #007bff;
}
.hover-text-underline:hover {
  text-decoration: underline;
  cursor: pointer;
}

.fields .filds__nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.fields p {
  width: 100%;
  margin-bottom: 0;
}

::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: #F6F6F6;
}

::-webkit-scrollbar-thumb {
  background: #dddddd;
}

.a__click{
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
}

.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu:not(.expanded) .navigation li.active a {
  color: #565656!important;
}

.main-menu.menu-light .navigation li a{
  color: white!important;
}

.load{
  padding: 50px;
  text-align: center;
}

#user-table {
  border-collapse: separate;
  border-spacing: 0 1rem;
}

#user-table .tableRowClass {
  background: white;
  transform: translateY(5px);
  transition: all 0.5s ease-in-out;
  text-align: center;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);

td {
  border-top: 0;
  padding: 20px !important;

&:first-child {
   border-top-left-radius: 0.5rem;
   border-bottom-left-radius: 0.5rem;
 }

&:last-child {
   border-top-right-radius: 0.5rem;
   border-bottom-right-radius: 0.5rem;
 }
}
}

#user-table .tableRowClass:hover {
  transform: translateY(0);
  background-color: #f6f6f9;
}

#user-table .tableHeadClass {
  background: none !important;
  border: none !important;
}

.tableHeadClass {
  text-align: center;
  padding-left: 0 !important;
}

.vs__open-indicator {
  fill: rgba(60, 60, 60, 0.5) !important;
}

.dark-layout h5 {
  color: #fff!important;
}

//.dark-layout .card button {
//  color: #fff!important;
//  border: 1px solid #fff!important;
//}

.dark-layout .flatpickr-calendar{
background-color: #283046;
}

.dark-layout .flatpickr-disabled {
  background: rgba(204, 204, 204, 0.15) !important;
  color: #fff!important;
}

.dark-layout .flatpickr-day {
  border-color: #959ea9!important;
  color: #fff!important;
}

.dark-layout .flatpickr-day.today {

}

.dark-layout .flatpickr-monthDropdown-months {
  color: #fff!important;
}

.dark-layout .numInputWrapper input{
  color: #fff!important;
}

.dark-layout .flatpickr-weekdaycontainer .flatpickr-weekday {
  color: #fff;
}

#personalAccount .form-control-plaintext {
  color: #000;
}

#statement .form-control-plaintext {
  color: #000;
}

.dark-layout #personalAccount .form-control-plaintext {
  color: #fff;
}

.dark-layout #statement .form-control-plaintext {
  color: #fff;
}
.dark-layout #user-table .tableRowClass {
  background-color: rgba(22, 29, 49, 0.38);
}

.form-control-plaintext {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.vs__deselect svg {
  fill: white;
}

.dark-layout .vs__selected {
  color: #fff!important;
  background-color: rgb(54 70 175 / 42%)!important;
}

@import '~flatpickr/dist/flatpickr.css';
</style>
