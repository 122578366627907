import ApiServices from '@/services/apiServices'

export class Cart extends ApiServices {
  constructor(url) {
    super(url)
  }

  getByType(type) {
    this.http.get(this.apiUrl + type)
  }

  getSkDebt(params) {
    return this.http.get(`${this.apiUrl}/skSetDebt/`, { params })
  }

  getSkDebtExcel(params) {
    return this.http({
      method: 'get',
      url: `${this.apiUrl}/export/skSetDebt/`,
      params,
      timeout: 0,
      responseType: 'blob',
    })
  }

  updateSkDebtManager({
    id,
    MANAGER,
  }) {
    return this.http.patch(`${this.apiUrl}/skSetDebt/${id}/`, { MANAGER })
  }

  updateSkDebtManagerTag({
    id,
    TAG,
  }) {
    return this.http.patch(`${this.apiUrl}/skSetDebt/${id}/`, { TAG })
  }

  getSkDebtCompanyList(params) {
    return this.http.get(`${this.apiUrl}/company/`, { params })
  }

  getSkDebtId(params) {
    return this.http.get(`${this.apiUrl}/skSetDebt/${params.id}/`)
  }

  getSkDebTPaymentHistoryModal(params) {
    return this.http.get(`${this.apiUrl}/skPaymentsList/${params.id}/debt_payment_history/`, { params })
  }

  updateSkDebtHistoryModal(data) {
    return this.http.patch(`${this.apiUrl}/skPayment/${data.id}/`, data)
  }

  deleteSkDebtHistoryModal(id) {
    return this.http.delete(`${this.apiUrl}/skPayment/${id}/`)
  }

  getSkStatementOfDebt(params) {
    return this.http.get(`${this.apiUrl}/skIncomeSource/${params.id}/debt_income_source/`, { params })
  }

  postStatementDebt(data) {
    return this.http.post(`${this.apiUrl}/skIncomeSource/`, data)
  }

  deleteStatementDebt(id) {
    return this.http.delete(`${this.apiUrl}/skIncomeSource/${id}/`)
  }

  updateStatementDebt(data) {
    return this.http.patch(`${this.apiUrl}/skIncomeSource/${data.id}/`, data)
  }

  updateDebtSkContact(data) {
    return this.http.patch(`${this.apiUrl}/skSetContact/${data.id}/`, data)
  }

  updateDebtzAYM(data) {
    return this.http.patch(`${this.apiUrl}/basis/${data.id}/`, data)
  }

  // property
  getDebtPropertyModal(params) {
    return this.http.get(`${this.apiUrl}/skProperty/${params.id}/contact_property/`, { params })
  }

  deleteDebtProperty(id) {
    return this.http.delete(`${this.apiUrl}/skProperty/${id}/`)
  }

  updateDebtProperty(data) {
    return this.http.patch(`${this.apiUrl}/skProperty/${data.id}/`, data)
  }

  // property

  // comment
  createCommentDebt(data) {
    return this.http.post('/utils/comments/', data)
  }

  getUtilsComment(params) {
    return this.http.get('/utils/comments/', { params })
  }

  deleteCommentDebt(id) {
    return this.http.delete(`/utils/comments/${id}/`)
  }

  // document modal debt
  createDebtDocument(data) {
    return this.http.post('/utils/attachments/', data)
  }

  getDebtDocument(params) {
    return this.http.get('/utils/attachments/', { params })
  }

  deleteDebtDocument(id) {
    return this.http.delete(`/utils/attachments/${id}/`)
  }

  deleteDebtDocumentFileData(id) {
    return this.http.delete(`/utils/attachments/${id}/attachment_files/`)
  }

  updateDebtDocument(data) {
    return this.http.patch(`/utils/attachments/${data.get('id')}/`, data)
  }

  // document modal debt end

  // history modal

  // /debts/skSetDebt/history/{debt_id}
  getSkDebtHistory(params) {
    return this.http.get(`/debts/skSetDebt/history/${params.id}`, { params })
  }

  // history modal end

  //  **************************** Претензионно-судебная работа ********************************* //
  getSkDebtJudicalWork(params) {
    return this.http.get(`${this.apiUrl}/skSetJudicalWork/${params.id}/debt_judical_work/`)
  }

  getSkDebtJudicalWorkFilterdDate(params) {
    return this.http.get(`${this.apiUrl}/skSetJudicalWork/${params.id}/`)
  }

  updateJudicalWork(data) {
    return this.http.patch(`${this.apiUrl}/skSetJudicalWork/${data.id}/`, data)
  }

  updateClaims(data) {
    return this.http.patch(`${this.apiUrl}/skSetClaim/${data.id}/`, data)
  }

  getSkDebtJudicalWorkClaimsModal(params) {
    return this.http.get(`${this.apiUrl}/skSetClaim/${params.id}/claim_judical_work/`, { params })
  }

  deleteSkDebtJudicalWorkClaimsModal(id) {
    return this.http.delete(`${this.apiUrl}/skSetClaim/${id}/`)
  }

  getSkDebtAdjudgement(params) {
    return this.http.get(`${this.apiUrl}/skSetAdjudgement/${params.id}/debt_adjudgement/`)
  }

  updateDebtAdjudmentModal(data) {
    return this.http.patch(`${this.apiUrl}/skSetAdjudgement/${data.id}/`, data)
  }

  //  **************************** Претензионно-судебная работа ********************************* //

  //  ****************************  ССП  ********************************* //
  getSkDebtCcp(params) {
    return this.http.get(`${this.apiUrl}/skSetExecutiveDocument/${params.id}/debt_executive_doc/`)
  }

  getSkDebtCcpButtonFilter(params) {
    return this.http.get(`${this.apiUrl}/skSetExecutiveDocument/${params.id}/`)
  }

  updateCcpSum(data) {
    return this.http.patch(`${this.apiUrl}/skSetExecutiveDocument/${data.id}/`, data)
  }

  getSkDebtCcpIp(params) {
    return this.http.get(`${this.apiUrl}/skSetExecutiveWork/${params.id}/debt_executive_work/`)
  }

  getSkDebtExecutiveDoc(params) {
    return this.http.get(`${this.apiUrl}/skSetDebt/${params.id}/get_borrower_and_guarantor/`)
  }

  updateIpModal(data) {
    return this.http.patch(`${this.apiUrl}/skSetExecutiveWork/${data.id}/`, data)
  }

  //  ****************************  ССП end ********************************* //

  getSkDebtTag(params) {
    return this.http.get(`${this.apiUrl}/skTag/`, { params })
  }
}

export default new Cart('/debts')
