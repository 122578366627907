export const ADD_ITEM = 'ADD_ITEM'
export const REMOVE_ITEM = 'REMOVE_ITEM'
export const SET_SK_DEBT = 'SET_SK_DEBT'
export const SET_SK_DEBT_COMPANY_LIST = 'SET_SK_DEBT_COMPANY_LIST'
export const SET_SK_DEBT_ID = 'SET_SK_DEBT_ID'
export const SET_SK_DEBT_PAYMENT_HISTORY_MODAL = 'SET_SK_DEBT_PAYMENT_HISTORY_MODAL'
export const SET_SK_STATEMENT_OF_DEBT = 'SET_SK_STATEMENT_OF_DEBT'
export const SET_COMMENT_DEBT = 'SET_COMMENT_DEBT'
export const SET_DEBT_DOCUMENT = 'SET_DEBT_DOCUMENT'
export const SET_SK_DEBT_HISTORY = 'SET_SK_DEBT_HISTORY'
export const SET_DEBT_PROPERTY_MODAL = 'SET_DEBT_PROPERTY_MODAL'

//  **************************** Претензионно-судебная работа ********************************* //
export const SET_SK_DEBT_JUDICAL_WORK = 'SET_SK_DEBT_JUDICAL_WORK'
export const SET_SK_DEBT_JUDICAL_WORK_FILTERED_DATE = 'SET_SK_DEBT_JUDICAL_WORK_FILTERED_DATE'
export const SET_SK_DEBT_JUDICAL_WORK_CLAIMS_MODAL = 'SET_SK_DEBT_JUDICAL_WORK_CLAIMS_MODAL'
export const SET_SK_DEBT_ADJUDGEMENT = 'SET_SK_DEBT_ADJUDGEMENT'
//  **************************** Претензионно-судебная работа end ********************************* //

//  ****************************  ССП  ********************************* //
export const SET_SK_DEBT_CCP = 'SET_SK_DEBT_CCP'
export const SET_SK_DEBT_CCP_BUTTON_FILTER = 'SET_SK_DEBT_CCP_BUTTON_FILTER'
export const SET_SK_DEBT_CCP_IP = 'SET_SK_DEBT_CCP_IP'
export const SET_SK_DEBT_EXECUTIVE_DOCUMENT = 'SET_SK_DEBT_EXECUTIVE_DOCUMENT'
//  ****************************  ССП end ********************************* //
export const SET_SK_DEBT_TAG = 'SET_SK_DEBT_TAG'
export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB'
export const SET_DOSSER_ID = 'SET_DOSSER_ID'
