export default {
  namespaced: true,
  state: {
    processProgress: 0,
  },
  getters: {
    getProcessProgress: state => state.processProgress,
  },
  mutations: {},
  actions: {
    UPDATE_PROCESS({ state }, val) {
      state.processProgress = val
    },
  },
}
