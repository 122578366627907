export default () => ({
  debtCompanyList: {
    count: 0,
    results: [],
  },

  debtCompanyListFilter: {
    count: 0,
    results: [],
  },
})
