import SkLegal from '../serveces/api'
import state from './state'
import mutations from './mutations'

export default {
  namespaced: true,
  state,
  mutations,

  actions: {
    async FETCH_SK_LEGAL({ commit }, params) {
      return new Promise((resolve, reject) => {
        SkLegal.getSkLegal(params)
          .then(r => {
            commit('SET_SK_LEGAL', r.data)
            resolve(r.data)
          })
          .catch(reject)
      })
    },

    async FETCH_SK_LEGAL_DETAIL({ commit }, params) {
      return new Promise((resolve, reject) => {
        SkLegal.getSkLegalDetail(params)
          .then(r => {
            commit('SET_SK_LEGAL_DETAIL', r.data)
            resolve(r.data)
          })
          .catch(reject)
      })
    },

    UPDATE_SK_LEGAL(_, data) {
      return SkLegal.updateSkLegal(data)
    },

    async FETCH_SK_LEGAL_DEBT({ commit }, params) {
      return new Promise((resolve, reject) => {
        SkLegal.getSkLegalDebt(params)
          .then(r => {
            commit('SET_SK_LEGAL_DEBT', r.data)
            resolve(r.data)
          })
          .catch(reject)
      })
    },

    async FETCH_SK_JUDICAL_WORK({ commit }, params) {
      return new Promise((resolve, reject) => {
        SkLegal.getSkJudicalWork(params)
          .then(r => {
            commit('SET_SK_JUDICAL_WORK', r.data)
            resolve(r.data)
          })
          .catch(reject)
      })
    },

    async FETCH_SK_JUDICAL_WORK_ID({ commit }, params) {
      return new Promise((resolve, reject) => {
        SkLegal.getSkJudicalWorkId(params)
          .then(r => {
            commit('SET_SK_JUDICAL_WORK_ID', r.data)
            resolve(r.data)
          })
          .catch(reject)
      })
    },

    UPDATE_SK_JUDICAL_WORK_ID(_, data) {
      return SkLegal.updateSkJudicalWorkId(data)
    },

    async FETCH_SK_LEGAL_ADJUDGEMENT({ commit }, params) {
      return new Promise((resolve, reject) => {
        SkLegal.getSkLegalAdjudgement(params)
          .then(r => {
            commit('SET_SK_LEGAL_ADJUDGEMENT', r.data)
            resolve(r.data)
          })
          .catch(reject)
      })
    },

    UPDATE_SK_LEGAL_ADJUDGEMENT(_, data) {
      return SkLegal.updateSkLegalAdjudgement(data)
    },

    UPDATE_SKLEGALEXECUTIVEDOCUMENT(_, data) {
      return SkLegal.updateskLegalExecutiveDocument(data)
    },

    async FETCH_SKLEGAL_EXECUTIVE_DOCUMENT({ commit }, params) {
      return new Promise((resolve, reject) => {
        SkLegal.getSkLegalExecutiveDoc(params)
          .then(r => {
            commit('SET_SKLEGAL_EXECUTIVE_DOCUMENT', r.data)
            resolve(r.data)
          })
          .catch(reject)
      })
    },

    async FETCH_SKLEGAL_EXECUTIVE_DOCUMENT_ID({ commit }, params) {
      return new Promise((resolve, reject) => {
        SkLegal.getSkLegalExecutiveDocId(params)
          .then(r => {
            commit('SET_SKLEGAL_EXECUTIVE_DOCUMENT_ID', r.data)
            resolve(r.data)
          })
          .catch(reject)
      })
    },

    async FETCH_SKLEGAL_EXECUTIVE_WORK({ commit }, params) {
      return new Promise((resolve, reject) => {
        SkLegal.getSkLegalExecutiveWork(params)
          .then(r => {
            commit('SET_SKLEGAL_EXECUTIVE_WORK', r.data)
            resolve(r.data)
          })
          .catch(reject)
      })
    },

    UPDATE_SK_LEGAL_EX_DOCUMENT(_, data) {
      return SkLegal.updateSkLegalExDocuent(data)
    },

    UPDATE_SK_LEGAL_DEBT(_, data) {
      return SkLegal.updateSkLegalDebt(data)
    },

    async FETCH_SK_TAGS({ commit }, params) {
      return new Promise((resolve, reject) => {
        SkLegal.getSkTags(params)
          .then(r => {
            commit('SET_SK_TAGS', r.data)
            resolve(r.data)
          })
          .catch(reject)
      })
    },
  },
}
