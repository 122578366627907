import state from './state'
import mutations from './mutations'
import getters from './getters'
import Cart from '../services/api'

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions: {
    UPDATE_SK_DEBT_MANAGER(_, data) {
      return Cart.updateSkDebtManager(data)
    },

    UPDATE_SK_DEBT__TAG(_, data) {
      return Cart.updateSkDebtManagerTag(data)
    },

    async FETCH_SK_DEBT({ commit }, params) {
      return new Promise((resolve, reject) => {
        Cart.getSkDebt(params)
          .then(r => {
            commit('SET_SK_DEBT', r.data)
            resolve(r.data)
          })
          .catch(reject)
      })
    },

    async FETCH_DEBT_EXCEL(_, params) {
      return new Promise(resolve => {
        Cart.getSkDebtExcel(params)
          .then(r => {
            resolve(r.data)
          })
      })
    },

    async FETCH_SK_DEBT_COMPANY_LIST({ commit }, params) {
      return new Promise((resolve, reject) => {
        Cart.getSkDebtCompanyList(params)
          .then(r => {
            commit('SET_SK_DEBT_COMPANY_LIST', r.data)
            resolve(r.data)
          })
          .catch(reject)
      })
    },

    async FETCH_SK_DEBT_ID({ commit }, params) {
      return new Promise((resolve, reject) => {
        Cart.getSkDebtId(params)
          .then(r => {
            commit('SET_SK_DEBT_ID', r.data)
            resolve(r.data)
          })
          .catch(reject)
      })
    },

    async FETCH_SK_DEBT_PAYMENT_HISTORY_MODAL({ commit }, params) {
      return new Promise((resolve, reject) => {
        Cart.getSkDebTPaymentHistoryModal(params)
          .then(r => {
            commit('SET_SK_DEBT_PAYMENT_HISTORY_MODAL', r.data)
            resolve(r.data)
          })
          .catch(reject)
      })
    },

    UPDATE_SK_DEBT_HISTORY_MODAL(_, data) {
      return Cart.updateSkDebtHistoryModal(data)
    },

    DELETE_SK_DEBT_HISTORY_MODAL(_, id) {
      return Cart.deleteSkDebtHistoryModal(id)
    },

    async FETCH_SK_STATEMENT_OF_DEBT({ commit }, params) {
      return new Promise((resolve, reject) => {
        Cart.getSkStatementOfDebt(params)
          .then(r => {
            commit('SET_SK_STATEMENT_OF_DEBT', r.data)
            resolve(r.data)
          })
          .catch(reject)
      })
    },

    UPDATE_SK_DEBT_SK_CONTACT(_, data) {
      return Cart.updateDebtSkContact(data)
    },

    CREATE_SK_STATEMENT_OF_DEBT(_, data) {
      return Cart.postStatementDebt(data)
    },

    DELETE_SK_STATEMENT_OF_DEBT(_, id) {
      return Cart.deleteStatementDebt(id)
    },

    UPDATE_SK_STATEMENT_OF_DEBL(_, data) {
      return Cart.updateStatementDebt(data)
    },

    UPDATE_SK_DEBT_ZAYM(_, data) {
      return Cart.updateDebtzAYM(data)
    },
    // property
    async FETCH_DEBT_PROPERTY_MODAL({ commit }, params) {
      return new Promise((resolve, reject) => {
        Cart.getDebtPropertyModal(params)
          .then(r => {
            commit('SET_DEBT_PROPERTY_MODAL', r.data)
            resolve(r.data)
          })
          .catch(reject)
      })
    },

    DELETE_DEBT_PROPERTY_MODAL(_, id) {
      return Cart.deleteDebtProperty(id)
    },

    UPDATE_DEBT_PROPERTY(_, data) {
      return Cart.updateDebtProperty(data)
    },
    // property

    // comment
    CREATE_COMMENT(_, data) {
      return Cart.createCommentDebt(data)
    },

    async FETCH_COMMENT_DEBT({ commit }, params) {
      return new Promise((resolve, reject) => {
        Cart.getUtilsComment(params)
          .then(r => {
            commit('SET_COMMENT_DEBT', r.data)
            resolve(r.data)
          })
          .catch(reject)
      })
    },

    DELETE_COMMENT(_, id) {
      return Cart.deleteCommentDebt(id)
    },
    // comment END

    // DOCUMENT modal
    CREATE_DEBT_DOCUMENT(_, data) {
      return Cart.createDebtDocument(data)
    },

    async FETCH_DEBT_DOCUMENT({ commit }, params) {
      return new Promise((resolve, reject) => {
        Cart.getDebtDocument(params)
          .then(r => {
            commit('SET_DEBT_DOCUMENT', r.data)
            resolve(r.data)
          })
          .catch(reject)
      })
    },

    DELETE_DEBT_DOCUMENT(_, id) {
      return Cart.deleteDebtDocument(id)
    },

    DELETE_DEBT_DOCUMENT_FILE_DATA(_, id) {
      return Cart.deleteDebtDocumentFileData(id)
    },
    // FORM DATA
    UPDATE_DEBT_DOCUMENT(_, data) {
      return Cart.updateDebtDocument(data)
    },
    // DOCUMENT modal end

    // HISTORY
    async FETCH_SK_DEBT_HISTORY({ commit }, params) {
      return new Promise((resolve, reject) => {
        Cart.getSkDebtHistory(params)
          .then(r => {
            commit('SET_SK_DEBT_HISTORY', r.data)
            resolve(r.data)
          })
          .catch(reject)
      })
    },
    // HISTORY END

    //  **************************** Претензионно-судебная работа ********************************* //
    async FETCH_SK_DEBT_JUDICAL_WORK({ commit }, params) {
      return new Promise((resolve, reject) => {
        Cart.getSkDebtJudicalWork(params)
          .then(r => {
            commit('SET_SK_DEBT_JUDICAL_WORK', r.data)
            resolve(r.data)
          })
          .catch(reject)
      })
    },
    // filter button date
    async FETCH_SK_DEBT_JUDICAL_WORK_FILTERED_DATE({ commit }, params) {
      return new Promise((resolve, reject) => {
        Cart.getSkDebtJudicalWorkFilterdDate(params)
          .then(r => {
            commit('SET_SK_DEBT_JUDICAL_WORK_FILTERED_DATE', r.data)
            resolve(r.data)
          })
          .catch(reject)
      })
    },
    // update judical work
    UPDATE_SK_DEBT_JUDICAL_WORK_FILTERED_DATE(_, data) {
      return Cart.updateJudicalWork(data)
    },
    // Претензии modal
    async FETCH_SK_DEBT_JUDICAL_WORK_CLAIMS_MODAL({ commit }, params) {
      return new Promise((resolve, reject) => {
        Cart.getSkDebtJudicalWorkClaimsModal(params)
          .then(r => {
            commit('SET_SK_DEBT_JUDICAL_WORK_CLAIMS_MODAL', r.data)
            resolve(r.data)
          })
          .catch(reject)
      })
    },

    UPDATE_CLAIMS(_, data) {
      return Cart.updateClaims(data)
    },

    DELETE_SK_DEBT_JUDICAL_WORK_FILTERED_DATE(_, id) {
      return Cart.deleteSkDebtJudicalWorkClaimsModal(id)
    },

    // sud
    async FETCH_SK_DEBT_ADJUDGEMENT({ commit }, params) {
      return new Promise((resolve, reject) => {
        Cart.getSkDebtAdjudgement(params)
          .then(r => {
            commit('SET_SK_DEBT_ADJUDGEMENT', r.data)
            resolve(r.data)
          })
          .catch(reject)
      })
    },
    UPDATE_SK_DEBT_ADJUDGEMENT_MODAL(_, data) {
      return Cart.updateDebtAdjudmentModal(data)
    },
    // sud
    //  **************************** Претензионно-судебная работа end ********************************* //

    //  ****************************  ССП  ********************************* //
    async FETCH_SK_DEBT_CCP({ commit }, params) {
      return new Promise((resolve, reject) => {
        Cart.getSkDebtCcp(params)
          .then(r => {
            commit('SET_SK_DEBT_CCP', r.data)
            resolve(r.data)
          })
          .catch(reject)
      })
    },

    async FETCH_SK_DEBT_CCP_FILTERED_DATE({ commit }, params) {
      return new Promise((resolve, reject) => {
        Cart.getSkDebtCcpButtonFilter(params)
          .then(r => {
            commit('SET_SK_DEBT_CCP_BUTTON_FILTER', r.data)
            resolve(r.data)
          })
          .catch(reject)
      })
    },

    UPDATE_SK_DEBT_CCP_SUM(_, data) {
      return Cart.updateCcpSum(data)
    },

    async FETCH_SK_DEBT_EXECUTIVE_DOCUMENT({ commit }, params) {
      return new Promise((resolve, reject) => {
        Cart.getSkDebtExecutiveDoc(params)
          .then(r => {
            commit('SET_SK_DEBT_EXECUTIVE_DOCUMENT', r.data)
            resolve(r.data)
          })
          .catch(reject)
      })
    },

    async FETCH_SK_DEBT_IP({ commit }, params) {
      return new Promise((resolve, reject) => {
        Cart.getSkDebtCcpIp(params)
          .then(r => {
            commit('SET_SK_DEBT_CCP_IP', r.data)
            resolve(r.data)
          })
          .catch(reject)
      })
    },

    UPDATE_IP_MODAL(_, data) {
      return Cart.updateIpModal(data)
    },
    //  ****************************  ССП end ********************************* //

    // tag
    async FETCH_SK_DEBT_TAG({ commit }, params) {
      return new Promise((resolve, reject) => {
        Cart.getSkDebtTag(params)
          .then(r => {
            commit('SET_SK_DEBT_TAG', r.data)
            resolve(r.data)
          })
          .catch(reject)
      })
    },
    // end tag
  },
}
