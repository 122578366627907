import state from './state'
import mutations from './mutations'
import file from '../serveces/api'

export default {
  namespaced: true,
  state,
  mutations,
  actions: {
    ADD_BREADCRUMB({ commit }, data) {
      commit('SET_BREADCRUMB', data)
    },

    async FETCH_DEBT_CLIENTS(context, params) {
      try {
        const response = await file.getDebtClients(params)
        const responseData = response.data

        context.state.debtClients.next = responseData.next
        context.state.debtClients.count = responseData.count

        if (params.page === 1) {
          context.state.debtClients = responseData
        } else {
          context.state.debtClients.results.push(...responseData.results)
        }

        return responseData
      } catch (error) {
        throw error
      }
    },

    async FETCH_BORROWER_LIST(context, params) {
      try {
        const response = await file.getBorrower(params)
        const responseData = response.data

        context.state.borrowerList.next = responseData.next
        context.state.borrowerList.count = responseData.count

        if (params.params.page === 1) {
          context.state.borrowerList = responseData
        } else {
          context.state.borrowerList.results.push(...responseData.results)
        }

        return responseData
      } catch (error) {
        throw error
      }
    },

    async FETCH_SK_DEBT_LIST(context, params) {
      try {
        const response = await file.getSkDebtList(params)
        const responseData = response.data

        context.state.skDebtList.next = responseData.next
        context.state.skDebtList.count = responseData.count

        if (params.params.page === 1) {
          context.state.skDebtList = responseData
        } else {
          context.state.skDebtList.results.push(...responseData.results)
        }

        return responseData
      } catch (e) {
        throw e
      }
    },

    async FETCH_DEBT_FILE_TYPES(context, params) {
      return new Promise((resolve, reject) => {
        file.getDebtFileTypes2(params)
          .then(r => {
            context.state.debtFileTypes = r.data
            resolve(r.data)
          })
          .catch(reject)
      })
    },

    async FETCH_DEBT_FILES(context, params) {
      try {
        const response = await file.getDebtFiles(params)
        const responseData = response.data

        context.state.debtFiles.next = responseData.next
        context.state.debtFiles.count = responseData.count

        if (params.page === 1) {
          context.state.debtFiles = responseData
        } else {
          context.state.debtFiles.results.push(...responseData.results)
        }

        return responseData
      } catch (e) {
        throw e
      }
    },

    // LAZY SELECT LIST
    async FETCH_DEBT_CLIENTS_LIST({ commit }, params) {
      return new Promise((resolve, reject) => {
        file.getDebtClientsList(params)
          .then(r => {
            commit('SET_DEBT_CLIENTS_LIST', r.data)
            resolve(r.data)
          })
          .catch(reject)
      })
    },

    FETCH_DOC_STATUS_LAZY({ commit }, params) {
      return file.getDocStatusLazy(params)
        .then(response => {
          commit('SET_DOC_STATUS', response.data)

          return response.data
        })
    },

    FETCH_ALL_USERS_LAZY({ commit }, params) {
      return file.getUsersLazy(params)
        .then(response => {
          commit('SET_ALL_USERS', response.data)

          return response.data
        })
    },

    async FETCH_UTILS_DOC_TYPE_LAZY({ commit }, params) {
      return file.getUtilsLazy(params)
        .then(response => {
          commit('SET_UTILS_DOC_LAZY', response.data)

          return response.data
        })
    },

    async FETCH_UTILS_DOC_TYPE_LAZY_SECOND(context, params) {
      const { data } = await file.getUtilsLazy2(params)
      context.state.utilsDocSecond = data
      return data
    },

    FETCH_RECEIVED_FROM_LAZY({ commit }, params) {
      return file.getReceiveFormLazy(params)
        .then(response => {
          commit('SET_RECEIVED_FROM_LAZY', response.data)

          return response.data
        })
    },

    FETCH_DEBT_CLIENTS_LAZY({ commit }, params) {
      return file.getDebtClients(params)
        .then(response => {
          commit('SET_DEBT_CLIENTS_LAZY', response.data)

          return response.data
        })
    },

    async FETCH_DEBT_CLIENTS_LAZY2(context, params) {
      const { data } = await file.getDebtClients2(params)
      context.state.debtClients2 = data
    },

    async FETCH_BORROWER_LIST_LAZY(context, params) {
      const { data } = await file.getBorrower2(params)
      context.state.borrowerList2 = data
    },

    FETCH_COMPANY_SHORT_LIST({ commit }, params) {
      return file.getCompanyShortList(params)
        .then(response => {
          commit('SET_COMPANY_SHORT_LIST', response.data)

          return response.data
        })
    },

    FETCH_SK_DEBT_LIST_LAZY({ commit }, params) {
      return file.getSkDebtList(params)
        .then(response => {
          commit('SET_SK_DEBT_LIST_LAZY', response.data)

          return response.data
        })
    },

    FETCH_DEBT_FILE_TYPES_LAZY({ commit }, params) {
      return file.getDebtFileTypes(params)
        .then(response => {
          commit('SET_DEBT_FILE_TYPES_LAZY', response.data)

          return response.data
        })
    },

    FETCH_DEBT_FILE_TYPES_LAZY_MODAL({ commit }, params) {
      return file.getDebtFileTypes(params)
        .then(response => {
          commit('SET_DEBT_FILE_TYPES_LAZY_MODAL', response.data)

          return response.data
        })
    },

    DELETE_DOC_ATTACH_FILE(_, id) {
      return file.deleteDocAttachFile(id)
    },

    // clear states
    CLEAR_BORROWER_LIST({ commit }) {
      commit('CLEAR_BORROWER_LIST_LAZY')
    },

    CLEAR_SK_DEBT_LIST({ commit }) {
      commit('CLEAR_SK_DEBT_LIST')
    },

    CLEAR_DEBT_FILE_TYPES({ commit }) {
      commit('CLEAR_DEBT_FILE_TYPES')
    },

    CLEAR_CLIENT({ commit }) {
      commit('CLEAR_CLIENT')
    },

    // CLEAR_BORROWER_LIST({ commit }) {
    //   commit('CLEAR_CLIENT')
    // },
    // clear states

    // LAZY SELECT LIST/*  */

    // Документ create
    CREATE_DOC(_, data) {
      return file.createDoc(data)
    },

    UPDATE_DOC(_, data) {
      return file.updateDoc(data)
    },

    CREATE_DOC_ATTACH(_, data) {
      return file.createDocAttach(data)
    },

    DELETE_DOC_ATTACH(_, id) {
      return file.deleteDocAttach(id)
    },

    UPDATE_DOC_ATTACH(_, data) {
      return file.updateDocAttach(data)
    },

    async FETCH_DOC_ATTACH(context, params) {
      return new Promise((resolve, reject) => {
        file.getDocAttach(params)
          .then(r => {
            context.state.docAttach = r.data
            resolve(r.data)
          })
          .catch(reject)
      })
    },

    FETCH_DEBT_SHORT_LIST({ commit }, params) {
      return file.getDebtShortList(params)
        .then(r => {
          commit('SET_DEBT_SHORT_LIST', r.data)
        })
    },

    FETCH_TITLE_LAZY_MODAL({ commit }, params) {
      return file.getLazyTitleList(params)
        .then(response => {
          commit('SET_TITLE_LAZY_MODAL', response.data)

          return response.data
        })
    },

    async FETCH_ACTION_TYPE_MODAL(context, params) {
      const { data } = await file.getActionType(params)
      context.state.actionType = data
    },

    async FETCH_DEBT_EXCEL_ARCHIVE(_, params) {
      return new Promise(resolve => {
        file.getFilesExcel(params)
          .then(r => {
            resolve(r.data)
          })
      })
    },
  },
}
