import state from './state'
import Action from '../services/api'

export default {
  namespaced: true,
  state,
  actions: {
    async FETCH_BPM_ACTION(context, params) {
      return new Promise((resolve, reject) => {
        Action.getBpmAction(params)
          .then(r => {
            context.state.bpmAction = r.data
            resolve(r.data)
          })
          .catch(reject)
      })
    },

    CREATE_BPM_ACTION(_, data) {
      return Action.createBpmAction(data)
    },

    UPDATE_BPM_ACTION(_, data) {
      return Action.updateBpmAction(data)
    },

    DELETE_BPM_ACTION(_, id) {
      return Action.deleteBpmAction(id)
    },

    async FETCH_SMS_TEMPLATE_TEXT(context, params) {
      return new Promise((resolve, reject) => {
        Action.getSmsTemplateText(params)
          .then(r => {
            context.state.getSmsTemplateText = r.data
            resolve(r.data)
          })
          .catch(reject)
      })
    },

    async FETCH_BPM_ACTION_TYPE(context, params) {
      return new Promise((resolve, reject) => {
        Action.getBpmActionType(params)
          .then(r => {
            context.state.getBpmActionTypes = r.data
            resolve(r.data)
          })
          .catch(reject)
      })
    },
  },
}
