import state from './state.js'
import mutations from './mutations.js'
import Cart from '../services/api.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions: {
    ADD_TO_CART({ commit }, product) {
      Cart.getByName('ismet')
    },
  },
}
