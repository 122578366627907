import ApiServices from '@/services/apiServices'

export class Users extends ApiServices {
  constructor(url) {
    super(url)
  }

  async patch(conf) {
    return await this.http.patch(`${this.apiUrl}/${conf?.get('id')}`, conf)
  }

  getRoles() {
    return this.http.get(`${this.baseURL}/roles`)
  }

  createUser(data) {
    return this.http.post(`${this.apiUrl}/register`, data)
  }
}

export default new Users('/users')
