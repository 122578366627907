import ApiServices from '@/services/apiServices'

export class Dk extends ApiServices {
  getDk(params) {
    return this.http.get('/dk/dkDebtWork', { params })
  }

  getCurrentId(params) {
    return this.http.get('/dk/get-active-debt', { params })
  }

  getDkStatistics(params) {
    return this.http.get('/dk/dk-work-header-statistics', { params })
  }

  getUsers(params) {
    return this.http.get('/users', { params })
  }

  updateDkOperator(data) {
    return this.http.post('/dk/update_dk_operator', data)
  }

  getXLSDk(params) {
    return this.http({
      method: 'get',
      url: '/dk/dkDebtWorkExport',
      params,
      timeout: 0,
      responseType: 'blob',
    })
  }
}
export default new Dk('/dk')
