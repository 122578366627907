import ApiServices from '@/services/apiServices'

export class SkLegal extends ApiServices {
  getSkLegal(params) {
    return this.http.get('/debts/skLegalContacts/', { params })
  }

  getSkLegalDetail({ id, params }) {
    return this.http.get(`/debts/skLegalContacts/${id}/`, { params })
  }

  updateSkLegal({ id, data }) {
    return this.http.patch(`/debts/skLegalContacts/${id}/`, data)
  }

  getSkLegalDebt({ id, params }) {
    return this.http.get(`/debts/skLegalDebt/${id}/`, { params })
  }

  getSkJudicalWork(params) {
    return this.http.get('/debts/skLegalJudicalWork/', { params })
  }

  getSkJudicalWorkId({ id, params }) {
    return this.http.get(`/debts/skLegalJudicalWork/${id}/`, { params })
  }

  getSkLegalAdjudgement({ id, params }) {
    return this.http.get(`/debts/skLegalAdjudgement/${id}/`, { params })
  }

  updateSkLegalAdjudgement({ id, data }) {
    return this.http.patch(`/debts/skLegalJudicalWork/${id}/`, data)
  }

  updateskLegalExecutiveDocument(data) {
    return this.http.patch(`/debts/skLegalExecutiveWork/${data.id}/`, data)
  }

  updateSkJudicalWorkId({ id, data }) {
    return this.http.patch(`/debts/basis/${id}/`, data)
  }

  getSkLegalExecutiveDoc(params) {
    return this.http.get('/debts/skLegalExecutiveDocument/', { params })
  }

  getSkLegalExecutiveDocId({ id, params }) {
    return this.http.get(`/debts/skLegalExecutiveDocument/${id}/`, { params })
  }

  getSkLegalExecutiveWork(params) {
    return this.http.get('/debts/skLegalExecutiveWork/', { params })
  }

  updateSkLegalExDocuent({ id, data }) {
    return this.http.patch(`/debts/skLegalExecutiveDocument/${id}/`, data)
  }

  updateSkLegalDebt({
    id,
    TAG,
  }) {
    return this.http.patch(`debts/skLegalDebt/${id}/`, { TAG })
  }

  getSkTags(params) {
    return this.http.get('/debts/skLegalTag/', { params })
  }
}
export default new SkLegal('/dk')
