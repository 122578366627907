import ApiServices from '@/services/apiServices'

export class Port extends ApiServices {
  getClaimantsPort(params) {
    return this.http.get('/debts/company/company_dk/', { params })
    // return this.http.get('/debts/company/', { params })
  }

  getClaimantsPortStatistics(params) {
    return this.http.get('/dk/dk-header-statistics', { params })
  }

  createClaimPost(data) {
    return this.http.post('/debts/company/', data)
  }

  updateClaimPost({ data, id }) {
    return this.http.patch(`/debts/company/${id}/`, data)
  }

  deleteClaimPost(id) {
    return this.http.delete(`/debts/company/${id}/`)
  }

  getSkDebtCompanyList(params) {
    return this.http.get('/dk/dkSetDebt/', { params })
  }

  downloadXls(params) {
    return this.http({
      method: 'get',
      url: '/dk/call-history-by-company-export',
      params,
      timeout: 0,
      responseType: 'blob',
    })
  }

  downloadXlsOperator(params) {
    return this.http({
      method: 'get',
      url: '/dk/statistics-by-operator-export',
      params,
      timeout: 0,
      responseType: 'blob',
    })
  }

  downloadXlsPaymentHistory(params) {
    return this.http({
      method: 'get',
      url: '/dk/payment-history-by-company-export?company=14661',
      params,
      timeout: 0,
      responseType: 'blob',
    })
  }

  getSecretKey(params) {
    return this.http.get('/secret_key', { params })
  }

  createKey(data) {
    return this.http.post('/secret_key', data)
  }
}
export default new Port('/')
