import state from './state.js'
import TodoList from '../services/api.js'

export default {
  namespaced: true,
  state,
  mutations: {},
  actions: {
    async FETCH_DK_ALL_OPERATOR(context, params) {
      return TodoList.getAllOperator(params)
        .then(res => {
          context.state.allOperatorList = res.data
        })
    },
  },
}
