import state from './state.js'
import mutations from './mutations.js'
import Cart from '../services/api.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions: {
    ADD_TO_CART({ commit }, product) {
      Cart.getByName('ismet')
    },

    async FETCH_SK_DEBT_ARCHIVE({ commit }, params) {
      return new Promise((resolve, reject) => {
        Cart.getSkDebtArchive(params)
          .then(r => {
            commit('SET_SK_DEBT_ARCHIVE', r.data)
            resolve(r.data)
          })
          .catch(reject)
      })
    },

    async FETCH_DEBT_EXCEL_ARCHIVE(_, params) {
      return new Promise(resolve => {
        Cart.getSkDebtExcelArchive(params)
          .then(r => {
            resolve(r.data)
          })
      })
    },
  },
}
