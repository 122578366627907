import ApiServices from '@/services/apiServices'

export class Call extends ApiServices {
  getCallHistory(params) {
    return this.http.get('/dk/call-history-by-client', { params })
  }

  getXLS(params) {
    return this.http({
      method: 'get',
      url: '/dk/call-history-by-client-export',
      params,
      timeout: 0,
      responseType: 'blob',
    })
  }
}
export default new Call('/call')
