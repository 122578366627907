<template>
  <date-picker
    v-model="dateValue"
    :model-config="returnFormat"
    :locale="locale"
    :masks="{ input: showFormat }"
    :timezone="timezone"
    :mode="mode"
    v-bind="otherProps"
  >
    <template v-slot="{ inputValue, togglePopover }">
      <b-input-group>
        <b-form-input
          :placeholder="placeholder"
          class="bg-white"
          :class="dateValue ? 'round-0' : ''"
          :value="getValues(inputValue)"
          :disabled="disabled"
          :readonly="readonly"
          @keydown="clear"
          @click="togglePopover()"
        />
        <b-input-group-append
          v-if="dateValue"
          is-text
          @click="reset"
        >
          <feather-icon
            icon="XIcon"
            class="cursor-pointer"
          />
        </b-input-group-append>
      </b-input-group>
    </template>
  </date-picker>
  <!-- for example -->

  <!--
      <vue-date-picker
        v-model="date"
        placeholder="sdfsdf"
        :return-format="{ type: 'string', mask: 'YYYY-MM-DD' }"
        show-format="DD.MM.YYYY"

         :other-props="{'is-range': true}"
         :other-props="{'first-day-of-week': 1}"
      />
  -->
</template>

<script>
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import { BFormInput, BInputGroupAppend, BInputGroup } from 'bootstrap-vue'

export default {
  components: {
    DatePicker,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
  },
  props: {
    // !
    value: {
      type: [String, Object],
      required: false,
      default: '',
    },
    // all Props
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    timezone: {
      type: String,
      required: false,
      default: '',
    },
    locale: {
      type: [String, Object],
      required: false,
      default: 'ru',
    },
    returnFormat: {
      type: Object,
      required: false,
      default: () => ({
        type: 'string',
        mask: 'YYYY-MM-DD',
      }),
    },
    showFormat: {
      type: String,
      required: false,
      default: 'DD.MM.YYYY',
    },
    mode: {
      type: String,
      required: false,
      default: 'date',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: true,
    },
    otherProps: {
      type: Object,
      required: false,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      dateValue: this.value,
    }
  },
  watch: {
    dateValue(val) {
      this.$emit('input', val)
    },
    value: {
      handler(val) {
        this.dateValue = val
      },
    },
  },
  methods: {
    reset() {
      this.dateValue = {}
    },

    clear(e) {
      if (e.code === 'Backspace') this.dateValue = null
    },

    getValues(inputValue) {
      if (typeof inputValue === 'string') return inputValue
      return [inputValue.start, inputValue.end].filter(e => e).join('-')
    },
  },
}
</script>

<style>
.round-0 {
  border-right: 0;
}
</style>
