import state from "./state.js";
// import Roles from "../services/api.js";

export default {
  namespaced: true,
  state,
  mutations: {
    // SET_ALL_ROLES(state, data) {
    //   state.roles = data;
    // },
    // SET_ROLE(state, data) {
    //   state.role = data;
    // },
    // SET_ALL_PERMISSIONS(state, data) {
    //   state.permissions = data;
    // },
  },
  actions: {
    // async FETCH_ALL_PERMISSIONS({ commit }, payload) {
    //   return new Promise((resolve, reject) => {
    //     Roles.getPermissions(payload)
    //       .then((r) => {
    //         commit("SET_ALL_PERMISSIONS", r.data);
    //         resolve(r.data);
    //       })
    //       .catch(reject);
    //   });
    // },
    // async FETCH_ALL_ROLES({ commit }, payload) {
    //   return new Promise((resolve, reject) => {
    //     Roles.getAll(payload)
    //       .then((r) => {
    //         commit("SET_ALL_ROLES", r.data);
    //         resolve(r.data);
    //       })
    //       .catch(reject);
    //   });
    // },
    // async FETCH_ROLE({ commit }, payload) {
    //   return new Promise((resolve, reject) => {
    //     Roles.getById(payload)
    //       .then((r) => {
    //         commit("SET_ROLE", r.data);
    //         resolve(r.data);
    //       })
    //       .catch(reject);
    //   });
    // },
    // async CREATE_ROLE({}, data) {
    //   return await Roles.post(data);
    // },
    // async PATCH_ROLE({}, data) {
    //   return await Roles.patch(data);
    // },
    // async DELETE_ROLE({}, data) {
    //   return await Roles.delete(data);
    // },
  },
};
