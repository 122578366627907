const Module = () => import('./Module.vue')
const Home = () => import('./views/Home.vue')

const moduleRoute = {
  path: '/not_prospective',
  component: Module,
  children: [
    {
      path: '/',
      name: 'not_prospective',
      component: Home,
    },
  ],
}

export default router => {
  router.addRoutes([moduleRoute])
}
