import ApiServices from '@/services/apiServices'

export class File extends ApiServices {
  getDebtClients(params) {
    return this.http.get('/debts/company/company_list/', { params })
  }

  getDebtClients2(params) {
    return this.http.get('/debts/company/company_short_list/', { params })
  }

  getDebtClientsList(params) {
    return this.http.get('/debts/company/company_list/', { params })
  }

  getBorrower(params) {
    return this.http.get(`/debts/skSetDebt/${params.id}/borrower_list/`, { ...params })
  }

  getBorrower2(params) {
    return this.http.get('/debts/skSetContact/borrower_short_list/', { params })
  }

  getCompanyShortList({ params, id }) {
    return this.http.get(`/debts/skSetDebt/${id}/company_short_list/`, { params })
  }

  getSkDebtList({ id, params }) {
    // return this.http.get(`/debts/skSetDebt/${id}/company_short_list/`, { params })
    return this.http.get(`/debts/skSetDebt/${id}/sk_debt_list/`, { params })
  }

  getDebtFileTypes({ id, params }) {
    return this.http.get(`/debts/skSetDebt/${id}/debt_file_types/`, { params })
  }

  getDebtFileTypes2({ id, params }) {
    return this.http.get(`/debts/skSetDebt/${id}/debt_file_types/`, { params })
  }

  getDebtFiles(params) {
    return this.http.get('/utils/attachments/', { params })
  }

  // Документ create
  createDoc(data) {
    return this.http.post('/utils/attachments/', data)
  }

  updateDoc(data) {
    return this.http.patch(`/utils/attachments/${data.id}/`, data.data)
  }

  createDocAttach(data) {
    return this.http.post('/edo/doc_manager/', data)
  }

  deleteDocAttach(id) {
    return this.http.delete(`/utils/attachments/${id}/attachment_files/`)
  }

  updateDocAttach({ id, data }) {
    return this.http.patch(`/edo/doc_manager/${id}/doc_manager_update/`, data)
    // return this.http.patch(`/edo/doc_manager/${id}/`, data)
  }

  getDocAttach({ id, params }) {
    return this.http.get(`/edo/doc_manager/${id}/doc_manager_detail/`, { params })
  }

  deleteDocAttachFile(id) {
    return this.http.delete(`/edo/doc_manager/${id}/`)
  }

  // lazy
  getDocStatusLazy(params) {
    return this.http.get('/edo/doc_status/', { params })
  }

  getUsersLazy(params) {
    return this.http.get('/users', { params })
  }

  getUtilsLazy(params) {
    return this.http.get('/utils/doc-type/', { params })
  }

  getUtilsLazy2(params) {
    return this.http.get('/utils/doc-type/get_tag_via_content_type/', { params })
  }

  getReceiveFormLazy(params) {
    return this.http.get('/edo/received_from/', { params })
  }

  getDebtShortList({ id, params }) {
    return this.http.get(`/debts/skSetDebt/${id}/debt_short_list/`, { params })
  }

  getLazyTitleList(params) {
    return this.http.get('/edo/doc_name/', { params })
  }

  getActionType(params) {
    return this.http.get('/task_manager/action_type/get_action_for_select/', { params })
  }

  getFilesExcel(params) {
    return this.http.get('/edo/export_edo/', { params, responseType: 'blob', timeout: 0 })
  }
}
export default new File('/')
