import dk from '../serveces/api'
import state from './state'
import mutations from './mutations'

export default {
  namespaced: true,
  state,
  mutations,

  actions: {
    async FETCH_DK_LIST(context, params) {
      return new Promise((resolve, reject) => {
        dk.getDk(params)
          .then(r => {
            context.state.dkList = r.data
            resolve(r.data)
          })
          .catch(reject)
      })
    },

    async FETCH_CURRENT_ID(context, params) {
      return new Promise((resolve, reject) => {
        dk.getCurrentId(params)
          .then(r => {
            context.state.currentId = r.data
            resolve(r.data)
          })
          .catch(reject)
      })
    },

    async FETCH_DK_STATISTICS(context, params) {
      return new Promise((resolve, reject) => {
        dk.getDkStatistics(params)
          .then(r => {
            context.state.dkStatistics = r.data
            resolve(r.data)
          })
          .catch(reject)
      })
    },
  },
}
