export default () => ({
  skLegal: {
    count: 0,
    results: [],
  },

  skLegalDetail: {
    legal_contacts: [],
    contact_phones: [],
    sk_borrower_debt: [],
    debt_info: {},
  },
  SetSkLegalDebt: {
    BASIS: {},
  },

  skJudicalWork: {
    count: 0,
    results: [],
  },

  skJudicalWorkId: {
    CONTACTS: [],
  },

  skLegalAdjudgement: {
    CONTACTS: [],
  },

  sklegalExecutiveDocument: {
    count: 0,
    results: [],
  },

  sklegalExecutiveDocumentId: {
    EXECUTIVE_WORK: {},
  },

  sklegalExecutiveWork: {
    results: [],
  },

  skTags: {
    results: [],
  },

  activeId: null,
})
