import * as types from './mutation-types.js'
import {
  SET_DOC_STATUS, SET_ALL_USERS, SET_UTILS_DOC_LAZY, SET_RECEIVED_FROM_LAZY, SET_BREADCRUMB, CLEAR_BORROWER_LIST_LAZY, CLEAR_DEBT_FILE_TYPES,
  SET_COMPANY_SHORT_LIST, SET_DEBT_SHORT_LIST, SET_TITLE_LAZY_MODAL, SET_UTILS_DOC_LAZY_SECOND,
} from './mutation-types.js'

export default {
  // breadcrumb
  [types.SET_BREADCRUMB](state, data) {
    state.breadcrumb.push(data)
  },

  SET_ARRAY(state) {
    state.breadcrumb = []
  },
  // breadcrumb

  [types.SET_DOC_STATUS](state, data) {
    state.docStatus = {
      ...data,
      results: [...state.docStatus.results, ...data.results],
    }
  },

  [types.SET_COMPANY_SHORT_LIST](state, data) {
    state.companyShortList = {
      ...data,
      results: [...state.companyShortList.results, ...data.results],
    }
  },

  [types.SET_ALL_USERS](state, data) {
    state.allUsers = {
      ...data,
      results: [...state.allUsers.results, ...data.results],
    }
  },

  [types.SET_UTILS_DOC_LAZY](state, data) {
    state.utilsDoc = {
      ...data,
      results: [...state.utilsDoc.results, ...data.results],
    }
  },

  [types.SET_UTILS_DOC_LAZY_SECOND](state, data) {
    state.utilsDocSecond = {
      ...data,
      results: data.results,
    }
  },

  [types.SET_RECEIVED_FROM_LAZY](state, data) {
    state.receivedFrom = {
      ...data,
      results: [...state.receivedFrom.results, ...data.results],
    }
  },

  [types.SET_DEBT_CLIENTS_LAZY](state, data) {
    state.debtClients = {
      ...data,
      results: [...state.debtClients.results, ...data.results],
    }
  },

  [types.SET_DEBT_CLIENTS_LAZY2](state, data) {
    state.debtClients2 = {
      ...data,
      results: [...state.debtClients2.results, ...data.results],
    }
  },

  SET_BORROWER_LIST_LAZY(state, data) {
    state.borrowerList2 = {
      ...data,
      results: [...state.borrowerList2.results, ...data.results],
    }
  },

  [types.SET_SK_DEBT_LIST_LAZY](state, data) {
    state.skDebtList = {
      ...data,
      results: [...state.skDebtList.results, ...data.results],
    }
  },

  [types.SET_DEBT_FILE_TYPES_LAZY](state, data) {
    state.debtFileTypes = {
      ...data,
      EXECUTIVE_WORK: [
        data.CONTENT_TYPE ? {
          ID: data.ID,
          TITLE: 'Долговое обязательство',
          CONTENT_TYPE: data.CONTENT_TYPE,
          OBJECT_ID: data.OBJECT_ID,
        } : null,
        ...state.debtFileTypes.EXECUTIVE_WORK, ...data.EXECUTIVE_WORK],
    }
  },

  [types.SET_DEBT_FILE_TYPES_LAZY_MODAL](state, data) {
    state.debtFileTypesModal = {
      ...data,
      EXECUTIVE_WORK: [
        data.CONTENT_TYPE ? {
          ID: data.ID,
          TITLE: 'Долговое обязательство',
          CONTENT_TYPE: data.CONTENT_TYPE,
          OBJECT_ID: data.OBJECT_ID,
        } : null,
        ...state.debtFileTypesModal.EXECUTIVE_WORK, ...data.EXECUTIVE_WORK],
    }
  },

  // clear states
  [types.CLEAR_BORROWER_LIST_LAZY](state, _) {
    state.borrowerList2 = {
      count: 0,
      results: [],
    }
  },

  [types.CLEAR_SK_DEBT_LIST](state, _) {
    state.skDebtList = {
      count: 0,
      results: [],
    }
  },

  [types.CLEAR_DEBT_FILE_TYPES](state) {
    state.debtFileTypesModal = {
      CONTENT_TYPE: null,
      EXECUTIVE_WORK: [],
      ID: null,
      OBJECT_ID: null,
    }
  },

  [types.CLEAR_CLIENT](state, _) {
    state.debtClients = {
      count: 0,
      results: [],
    }
  },

  SET_FILTERS(state, data) {
    state.filters = data
  },
  SET_FILTERS_EMPTY(state, data) {
    state.filters = {}
  },
  SET_STEP(state, data) {
    state.step = data
  },

  SET_PARAMS(state, data) {
    state.params = data
  },

  SET_ACTIVEBORROW(state, data) {
    state.activeBorrow = data
  },
  SET_ACTIVEFILETYPE(state, data) {
    state.activeFileType = data
  },
  SET_DEBT_FILES(state, data) {
    state.debtFiles2 = data
  },

  SET_EMPTY_BORROWER(state) {
    state.borrowerList = {
      count: 0,
      results: [],
    }
  },
  SET_EMPTY_DEBT_FILES(state) {
    state.debtFiles = {
      count: 0,
      results: [],
    }
  },

  SET_EMPTY_COMPANY_SHORT_LIST(state) {
    state.companyShortList = {
      count: 0,
      results: [],
    }
  },

  SET_EMPTY_DEBTFILE(state) {
    state.debtFileTypes = {
      CONTENT_TYPE: null,
      EXECUTIVE_WORK: [],
      ID: null,
      OBJECT_ID: null,
    }
  },

  SET_BORROW_SPIN(state, data) {
    state.borrowSpin = data
  },

  [types.SET_DEBT_SHORT_LIST](state, data) {
    state.debtShortList = {
      ...data,
      results: [...state.debtShortList.results, ...data.results],
    }
  },

  SET_EMPTY_DEBT_SHORT_LIST(state) {
    state.debtShortList = {
      count: 0,
      results: [],
    }
  },

  [types.SET_TITLE_LAZY_MODAL](state, data) {
    state.lazyTitleList = {
      ...data,
      results: [...state.lazyTitleList.results, ...data.results],
    }
  },
}
