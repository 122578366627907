import ApiServices from '@/services/apiServices'

export class Users extends ApiServices {
  constructor(url) {
    super(url)
  }

  getPermissions(params) {
    return this.http.get(`${this.baseURL}/permissions`, { params })
  }
}

export default new Users('/roles')
