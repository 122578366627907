export default () => ({
  userList: {
    results: [],
  },
  userDetail: {},
  roles: [],
  actionTypes: [
    {
      id: 1,
      name: 'name',
    },
  ],

  statuses: [
    {
      id: 1,
      name: 'name',
    },
  ],
  states: [
    {
      id: 1,
      name: 'name',
    },
  ],
})
