import state from './state'
import port from '../serveces/api'

export default {
  namespaced: true,
  state,
  actions: {
    async FETCH_CLAIMANT_PORT(context, params) {
      return new Promise((resolve, reject) => {
        port.getClaimantsPort(params)
          .then(r => {
            context.state.claimantsData = r.data
            resolve(r.data)
          })
          .catch(reject)
      })
    },

    async FETCH_CLAIMANT_PORT_STATISTICS(context, params) {
      const res = await port.getClaimantsPortStatistics(params)
      context.state.statistics = res.data
    },

    CREATE_CLAIMANT_TYPE(_, data) {
      return port.createClaimPost(data)
    },

    UPDATE_CLAIMANT_TYPE(_, data) {
      return port.updateClaimPost(data)
    },

    DELETE_CLAIMANT_TYPE(_, id) {
      return port.deleteClaimPost(id)
    },

    async FETCH_SK_DEBT_COMPANY_LIST(context, params) {
      return new Promise((resolve, reject) => {
        port.getSkDebtCompanyList(params)
          .then(r => {
            context.state.debtCompanyList = r.data
            resolve(r.data)
          })
          .catch(reject)
      })
    },

    DOWNLOAD_CALL_HIS_XLS(_, data) {
      return port.downloadXls(data)
    },

    DOWNLOAD_CALL_HIS_XLS_OPERATOR(_, data) {
      return port.downloadXlsOperator(data)
    },

    DOWNLOAD_XLS_PAYMENT_HISTORY(_, data) {
      return port.downloadXlsPaymentHistory(data)
    },

    async FETCH_SECRET_KEY(context, params) {
      return new Promise((resolve, reject) => {
        port.getSecretKey(params)
          .then(r => {
            context.state.secretKey = r.data
            resolve(r.data)
          })
          .catch(reject)
      })
    },

    CREATE_KEY(_, data) {
      return port.createKey(data)
    },
  },
}
