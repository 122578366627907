import state from './state.js'
import Action from '../services/api.js'

export default {
  namespaced: true,
  state,
  mutations: {

  },
  actions: {},
}
