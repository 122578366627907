import ApiServices from '@/services/apiServices'

export class Dk extends ApiServices {
  getDk(params) {
    return this.http.get('/dk/dkDebtWork', { params })
  }

  createGuarantor({ data, id }) {
    return this.http.patch(`/debts/guarantor/${id}/`, data)
  }

  getDkDebtId(params) {
    return this.http.get(`/dk/dkSetDebt/${params.id}/`)
  }

  updateClaimerCompany({ data, id }) {
    return this.http.patch(`/debts/company/${id}/`, data)
  }

  updateBasisContract({ data, id }) {
    return this.http.patch(`/debts/skSetContact/${id}/`, data)
  }

  updateBasis({ data, id }) {
    return this.http.patch(`/debts/basis/${id}/`, data)
  }

  updateSkSetContact({ data, id }) {
    return this.http.patch(`/debts/skSetContact/${id}/`, data)
  }

  // statistic cards
  getSkDebTPaymentHistoryModal({ params, id }) {
    return this.http.get(`debts/skPaymentsList/${id}/debt_payment_history/`, { params })
  }

  getDkDebtOther(params) {
    return this.http.get('/dk/dkSetDebt/', { params })
  }

  // comments
  createCommentDebt(data) {
    return this.http.post('/utils/comments/', data)
  }

  getUtilsComment(params) {
    return this.http.get('/utils/comments/', { params })
  }

  deleteUtilsComment(id) {
    return this.http.delete(`/utils/comments/${id}/`)
  }

  // document modal debt
  createDebtDocument(data) {
    return this.http.post('/utils/attachments/', data)
  }

  getDebtDocument(params) {
    return this.http.get('/utils/attachments/', { params })
  }

  // promise
  createPromise(data) {
    return this.http.post('/dk/dkPromiseDebt/', data)
  }

  createHistory(data) {
    return this.http.post('/dk/status-history', data)
  }

  getPromiseList(params) {
    return this.http.get('/dk/dkPromiseDebt/', { params })
  }

  getHistoryTimeLine(params) {
    return this.http.get('/dk/status-history', { params })
  }

  getDkHeaderStatistics(params) {
    return this.http.get('/dk/dk-header-detail-statistics', { params })
  }

  getSkStatus(params) {
    return this.http.get('/dk/get-status-debt', { params })
  }

  createCallHistory(data) {
    return this.http.post('/dk/call-history', data)
  }

  sendCloseDebt(data) {
    return this.http.post('/dk/close-debt', data)
  }

  createContact(data) {
    return this.http.post('/dk/dkContactPhone/', data)
  }

  updateContact(data) {
    return this.http.patch(`/dk/dkContactPhone/${data.id}/`, data)
  }

  getDkHeaderCallHistory(params) {
    return this.http.get('/dk/call-history', { params })
  }

  getDkHeaderSmsHistory(params) {
    return this.http.get('/dk/sms-history', { params })
  }

  createReminder(data) {
    return this.http.post('/dk/dkReminderDebt/', data)
  }

  getReminder(params) {
    return this.http.get('/dk/dkReminderDebt/', { params })
  }
}
export default new Dk('/dk')
