import not from '@/modules/notProspective/serveces/api'
import state from './state'
import mutations from './mutations'

export default {
  namespaced: true,
  state,
  mutations,
  actions: {
    async FETCH_SK_DEBT_COMPANY_LIST(context, params) {
      return new Promise((resolve, reject) => {
        not.getSkDebtCompanyList(params)
          .then(r => {
            context.state.debtCompanyList = r.data
            resolve(r.data)
            console.log(r.data)
          })
          .catch(reject)
      })
    },

    async FETCH_SK_DEBT_COMPANY_LIST_FILTER(context, params) {
      return new Promise((resolve, reject) => {
        not.getSkDebtCompanyListFilter(params)
          .then(r => {
            context.state.debtCompanyListFilter = r.data
            resolve(r.data)
          })
          .catch(reject)
      })
    },
  },
}
