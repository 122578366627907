const Module = () => import('./Module.vue')
const Home = () => import('./views/Home.vue')

const moduleRoute = {
  path: '/process',
  component: Module,
  children: [
    {
      path: '/process-settings',
      component: Home,
      name: 'process-settings',
    },
  ],
}

export default router => {
  router.addRoutes([moduleRoute])
}
