export default () => ({
  dkList: {
    results: [],
  },

  currentId: {
    current_id: null,
    next_id: null,
    prev_id: null,
  },

  dkStatistics: {
    count_do: 0,
    summa_do: 0,
    call_count: 0,
    promise_count: 0,
    finished_promise_count: 0,
    closed_debt: 0,
  },

  users: {
    results: [],
  },

  LAST_ACTION_NAME: [
    {
      title: 'Неправильный номер',
      value: 'secondary',
    },
    {
      title: 'Связь прервалась',
      value: 'primary',
    },
    {
      title: 'Обещание',
      value: 'warning',
    },
    {
      title: 'Успех',
      value: 'success',
    },
    {
      title: 'Отказ',
      value: 'light-danger',
    },
    {
      title: 'Неперспективный',
      value: 'danger',
    },
    {
      title: 'Не ответил',
      value: 'info',
    },
    {
      title: 'Без статуса',
      value: 'null',
    },
    {
      title: 'Обещание выполнено',
      value: 'warning-closed',
    },
    {
      title: 'Обещание истекло',
      value: 'warning-expired',
    },
    {
      title: 'Оплачено',
      value: 'success',
    },
  ],
})
