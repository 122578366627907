import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import VcDatePicker from '@/components/VcDatePicker.vue'
import BCPagination from '@/components/BCPagination.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

Vue.component(FeatherIcon.name, FeatherIcon)
Vue.component('VcDatePicker', VcDatePicker)
Vue.component('BCPagination', BCPagination)
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
