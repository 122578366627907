import state from './state.js'
import mutations from './mutations.js'
import Act from '../services/api.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions: {
    ADD_TO_CART({ commit }, product) {
      Act.getByName('ismet')
    },
    //  ****************************  Действия ********************************* //
    CREATE_DEBT_ACTION(_, data) {
      return Act.createDebtAction(data)
    },

    UPDATE_DEBT_ACTION(_, data) {
      return Act.updateDebtAction(data)
    },

    async FETCH_DEBT_ACTION({ commit }, params) {
      return new Promise((resolve, reject) => {
        Act.getSkDetActions(params)
          .then(r => {
            commit('SET_DEBT_ACTION', r.data)
            resolve(r.data)
          })
          .catch(reject)
      })
    },
    UPDATE_ACTIONS(_, data) {
      return Act.updateActions(data)
    },

    UPDATE_ACTIONS_CHECKBOX(_, data) {
      return Act.updateActionsCheckbox(data)
    },

    async FETCH_DEBT_ACTION_EXCEL(_, params) {
      return new Promise(resolve => {
        Act.getSkDebtExcelAction(params)
          .then(r => {
            resolve(r.data)
          })
      })
    },
    //  ****************************  Действия end ********************************* //
  },
}
